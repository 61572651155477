import { Nav, Navbar, Container } from 'react-bootstrap';
import { Images } from '../../assets';
import React, { useContext, useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import { ACCESS_TOKEN, MERCHANT_DETAILS } from '../../api/constants';

const NavigationBar = () => {

  let initialAccountManager = {
    account_manager_name: "Fam",
    account_manager_email: "merchants@joinfam.com",
    account_manager_phone_number: "0753 922 7305"
  }
  let [accountManager, setAccountManager] = useState(initialAccountManager)
  const company_name = 'Fam';
  const company_email = 'merchants@joinfam.com';
  const company_phone = '0753 922 7305';
  let [login, setLogin] = useState('false');

  useEffect(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (accessToken) {
      setLogin('true');
     let accountManagerDetails = localStorage.getItem(MERCHANT_DETAILS);
     if(accountManagerDetails){
       initialAccountManager = JSON.parse(accountManagerDetails)
     }
     setAccountManager(initialAccountManager)
    }
  }, []);

  return (
    <div>
      <Navbar
        className='navigation-bar-container nav-bar-img'
        collapseOnSelect
        expand='lg'
      >
        <Navbar.Brand
          href='#home'
          className='navigation-bar-container__brandImage'
        >
          <img src={Images.logoImage} alt='Not showing'></img>
        </Navbar.Brand>
        <Navbar.Toggle
          className='navigation-bar-container__toggle'
          aria-controls='responsive-navbar-nav'
        />

        {login == "true" ? (
          <Navbar.Collapse
            className='navigation-bar-container__collapse'
            id='responsive-navbar-nav'
          >
            <Nav className='ms-auto navigation-bar-items nav-bar-left'>
              <span>Account Manager :</span>
              <Nav.Link
                className='navigation_contact_info'
                eventKey='2'
                href='#'
              >
                {accountManager.account_manager_name }
              </Nav.Link>
              <Divider
                style={{
                  height: '21px',
                  backgroundColor: 'white',
                  borderColor: 'white',
                  borderRadius: '1px',
                  width: '2px',
                  marginTop: '2px',
                  marginLeft: '0px',
                  marginRight: '0px',
                  color: 'white',
                  opacity: 0.9,
                }}
                orienntation='vertical'
                variant='middle'
              />
              <Nav.Link eventKey='3' href={`mailto:${accountManager.account_manager_email}`} >
                {accountManager.account_manager_email}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        ) : (
          <p></p>
        )}
      </Navbar>
    </div>
  );
};

export default NavigationBar;

import React from 'react';

const SelectInputBox = ({
  className,
  data,
  onChange,
  name,
  id,
  code,
  value,
  title,
  type,
  selected,
}) => {
  return (
    <div className='select_option'>
      <select value={selected} className={className} onChange={onChange} name={name} id={id}>
        {data &&
          data.map((item, i) => {
            return (
              <option key={i} value={item[value]}>
                {item[title]}
              </option>
            );
          })}
      </select>
      <span className='select_arrow'></span>
    </div>
  );
};

export default SelectInputBox;

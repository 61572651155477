import React from 'react';
import SelectInputBox from '../select_input/SelectInput';

const EntityType = ({ className, id, name, onChange,selected }) => {
  const data = [
    {
      name: 'Registered Company',
      value: 'REGISTERED_COMPANY',
    },

    {
      name: 'Partnership',
      value: 'PARTNERSHIP',
    },
    {
      name: 'Sole Trader',
      value: 'SOLE_TRADER',
    },
  ];

  return (
    <SelectInputBox
      onChange={onChange}
      data={data}
      className={className}
      id={id}
      name={name}
      title={'name'}
      value={'value'}
      selected={selected}
    ></SelectInputBox>
  );
};

export default EntityType;

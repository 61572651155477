import React from 'react';
import { Images } from '../../assets';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const SelectCountry = ({ className, onChange, id, name }) => {
  return (
    <div className='select_option'>
      <Select
        className={className}
        onChange={onChange}
        defaultValue='United Kingdom'
        id={id}
        name={name}
      >
        <MenuItem default value='United Kingdom'>
          <div>
            {/* {Home.scss} */}
            <img className='flag_country' src={Images.ukflag}></img>
            <span className='text_country'>United Kingdom</span>
          </div>
        </MenuItem>
        <MenuItem disabled value='Not in UK'>
          <span className='text_country'>Not in UK? Get in touch.</span>
        </MenuItem>
      </Select>
    </div>
  );
};

export default SelectCountry;

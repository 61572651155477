import React, { useState, useEffect } from 'react';
import NavigationBar from '../navigation_bar/NavigationBar';
import './ConfirmOtp.scss';
import OtpInput from 'react-otp-input';
import { Divider } from '@mui/material';
import { Images } from '../../assets';
import { verifyOtp, signUpOnboard,getMerchantDetails } from '../../api';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { signUpAuthentication } from '../../api';
import { saveAuth } from '../../state/slices/authSlice';
import { saveMerchantDetails, setLoading } from '../../state/slices/testmodeSlice';
import { Spinner } from 'reactstrap';
import { localStorageItems } from '../../utility/helper';
import { useDispatch } from 'react-redux';

const ConfirmOtp = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const [remTime, setRemTime] = useState(30);
  const [showRemTime, setShowRemTime] = useState(true);
  const [isVerifying, setVerify] = useState(false);
  const [otpVerifyStatus, setOtpStatus] = useState('');
  const [isResendOtp, setResendOtp] = useState(false);
  const [otp, setOtp] = useState('');

  const formatToStr = (time) => {
    const min = Math.floor(time / 60);
    const sec = time - min * 60;

    return `${min}:${sec.toString().length === 2 ? sec : '0' + sec}`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (!remTime) setShowRemTime(false);
      setRemTime(remTime <= 0 ? 0 : remTime - 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    if (isResendOtp)
      setTimeout(() => {
        setResendOtp(false);
      });
  }, [isResendOtp]);

  const email = location.state;

  useEffect(() => {
    if (otp?.length == 4) handleSubmit();
  }, [otp]);

  const handleChange = (otp) => setOtp(otp);

  const handleSubmit = async (e) => {
    try {
      if (otp === '') {
        swal({
          title: 'failure',
          text: 'No OTP submitted',
          icon: 'error',
        });
      } else {
        // setVerify(true);
        // setOtpStatus('');
        const res = await verifyOtp({
          email: email,
          code: otp,
        });
        if (res?.data?.code === 200) {
          setOtpStatus('');
          setVerify(false);
          //navigate('/onBoarding');
          const login_info = localStorage.getItem('login_info');
          let lastData = {
            login_info: JSON.parse(login_info),
          };
          apiCall1(lastData);
        } else {
          setOtpStatus(res?.response?.data?.errors[0]?.detail);
          setVerify(true);
        }
      }
    } catch (e) {
      setVerify(false);
      setOtpStatus(`${e?.response?.data?.errors[0]?.detail}`);
    }
  };


  const apiCall1 = async (values) => {
    const response = await signUpOnboard(values);
    if (response?.status === 201) {
      localStorageItems(response);
      dispatch(saveAuth(response?.data?.data));
      getMerchantData(response?.data?.data?.user?.merchant_user_id);
      localStorage.setItem('merchant_pk',response?.data?.data?.user?.merchant?.merchant_pk);
      localStorage.removeItem('login_info');
      localStorage.removeItem('store_details');
      localStorage.removeItem('company_info');
      localStorage.removeItem('bank_details');
      localStorage.removeItem('key_persons');
      localStorage.removeItem('clone_seller_members');
      localStorage.removeItem('contracts_persons');      
      localStorage.removeItem('stakeholder');
      navigate('/loading');
    }
  };

  const getMerchantData = async (id) => {
    dispatch(setLoading(true));
    const response = await getMerchantDetails(id);
    dispatch(setLoading(false));
    if (response?.data?.code === 200) {
      dispatch(
        saveMerchantDetails(
          response?.data?.data?.account_details?.account_status
        )
      );
    }else if (response?.data?.code === 403) {
      dispatch(saveMerchantDetails('INREVIEW'));
    }
  };
  const handleResendOtp = async () => {
    setShowRemTime(true);
    setRemTime(30);

    try {
      const res = await signUpAuthentication({ email: email });
      setResendOtp(res.data.code == 200 ? true : false);
    } catch (e) {
      swal('Something Went Wrong');
    }
  };

  return (
    <div className='otp_container'>
      <div>
        <NavigationBar />
      </div>
      <div className='otp_details'>
        <div className='otp_details__content'>
          Check your inbox
          <p className='otp_details__content_email'>
            We've sent a 4-digit login code to {email}, enter it below to
            continue.
          </p>
        </div>
        <div className={isVerifying ? 'error-style' : ''}>
          {' '}
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            separator={<span> </span>}
            containerStyle='otp_details__input-container'
            inputStyle='otp_details__input-container__style'
            shouldAutoFocus={true}
            isInputNum={true}
          />
        </div>

        <div className='otp_details__resend_container'>
          <Link to='/signup'>
            <span className='otp_details__resend_container__different_email'>
              <span className='otp_details__resend_container__different_email__arrow'>
                <Images.smallArrowLeft />
              </span>
              Use a different email
            </span>
          </Link>

          <Divider
            style={{
              height: '21px',
              border: '1px solid black',
              width: '2px',
              color: 'black',
              opacity: 0.9,
              backgroundColor: 'black',
            }}
            orienntation='vertical'
            variant='middle'
          ></Divider>
          <button
            className={`${
              isResendOtp || !showRemTime
                ? 'otp_details__resend_container__enable_code'
                : 'otp_details__resend_container__diasable_code'
            }`}
            disabled={!showRemTime ? false : true}
            onClick={handleResendOtp}
          >
            {isResendOtp ? (
              <></>
            ) : (
              <>
                Resend code
                {showRemTime && (
                  <span className='p-0'> in&nbsp;{formatToStr(remTime)}</span>
                )}{' '}
              </>
            )}
          </button>
        </div>
        <div className='otp_details__confirm_condition'>
          By continuing you agree to our Terms of Service
        </div>
      </div>
    </div>
  );
};

export default ConfirmOtp;

import React, { useState,useEffect } from 'react';
import './UserAccessSetup.scss';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Images } from '../../assets';
import { signUpAuthentication,validateOnboarding } from '../../api';
import { useParams } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';

const UserAccessSetup = () => {
  const nameReg = /^[a-zA-Z]*$/;
  const navigate = useNavigate();
  const params = useParams();
  
  useEffect(() => {
    validatBoarding();
  }, []);

  const validationSchema = Yup.object().shape({
    name_input: Yup.string()
      .matches(nameReg, 'Letters only please (A-Z)')
      .required(''),
    name_last_name: Yup.string()
      .matches(nameReg, 'Letters only please (A-Z)')
      .required(''),
    email_input: Yup.string().email('Please enter a valid email').required(''),
    password_input: Yup.string()
      .required('')
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword_input: Yup.string()
      .oneOf([Yup.ref('password_input'), ''], 'Password must match')
      .required(''),
  });

  const onSubmit = (values) => {
    if (validationSchema) {
      const postAPI = {
        email: values.email_input,
        password: values.password_input,
        first_name: values.name_input,
        last_name: values.name_last_name,
        on_boarding_id: params.id
      };
      localStorage.setItem('on_boarding_id',params.id);
      localStorage.setItem('login_info', JSON.stringify(postAPI));
      localStorage.removeItem('company_info');
      localStorage.removeItem('clone_seller_members');
      localStorage.removeItem('key_persons');
      localStorage.removeItem('store_details');
      localStorage.removeItem('stakeholder');

      const login_info = localStorage.getItem('login_info');
      let lastApiData = JSON.parse(login_info);
      apiCall(lastApiData);
    }
  };

  const apiCall = async (values) => {
      const response = await signUpAuthentication({
        email: values.email,
        already_exists: true
      });
      if (response.status === 200) {
        navigate('/signup/confirm', { state: values.email });
      }else if(response.status === 400){
        toast.error((response.data.errors[0].detail?response.data.errors[0].detail:"Invalid request"))
      }
  };
  
  const validatBoarding= async ()=>{
    try {
        const res = await validateOnboarding(params.id);
        if (res.status !== 200) {
            navigate('/');
            toast.error('Invalid request');
        }
    } catch (error) {
        navigate('/');
        toast.error('Invalid request');
    }
  }

  return (
    <div className='setup_access_user'>
      <Formik
        initialValues={{
          name_input: '',
          name_last_name: '',
          email_input: '',
          password_input: '',
          confirmPassword_input: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          dirty,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className='input_field_container'>
              <label
                className='input_field_container__label'
                htmlFor='email_input_label'
              >
                Full name
              </label>
              <div className='input_field_container__container_input_box'>
                <Container className='input_field_container__container_input_box__container'>
                  <Row className='input_field_container__container_input_box__container__row'>
                    <Col className='input_field_container__container_input_box__container__row__col1'>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.name_input && touched.name_input
                            ? 'input_field_container__container_input_box__errors_fullname'
                            : 'input_field_container__container_input_box__input_field_fullname'
                        }
                        id='email_input_label'
                        name='name_input'
                        placeholder='First name'
                      ></input>

                      {errors.name_input && touched.name_input ? (
                        <div className='input_field_container__container_input_box__fullname_error_color'>
                          {errors.name_input}
                        </div>
                      ) : null}
                    </Col>
                    <Col className='input_field_container__container_input_box__container__row__col2'>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          errors.name_last_name && touched.name_last_name
                            ? 'input_field_container__container_input_box__errors_fullname'
                            : 'input_field_container__container_input_box__input_field_lastname'
                        }
                        id='email_input_label'
                        name='name_last_name'
                        placeholder='Last name'
                      ></input>
                      {errors.name_last_name && touched.name_last_name ? (
                        <div className='input_field_container__container_input_box__fullname_error_color'>
                          {errors.name_last_name}
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className='input_field_container'>
              <label
                className='input_field_container__label'
                htmlFor='email_input_label'
              >
                Email address
              </label>
              <div className='input_field_container__container_input_box'>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email_input && touched.email_input
                      ? 'input_field_container__container_input_box__errors'
                      : 'input_field_container__container_input_box__input_field'
                  }
                  id='email_input_label'
                  name='email_input'
                  placeholder='john@merchant.com'
                ></input>
                {errors.email_input && touched.email_input ? (
                  <div className='input_field_container__container_input_box__error_color'>
                    {errors.email_input}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='input_field_container'>
              <label
                className='input_field_container__label'
                htmlFor='password_input_label'
              >
                Password
              </label>
              <div className='input_field_container__container_input_box'>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='password'
                  className={
                    errors.password_input && touched.password_input
                      ? 'input_field_container__container_input_box__errors'
                      : 'input_field_container__container_input_box__input_field'
                  }
                  id='password_input_label'
                  name='password_input'
                  placeholder='●●●●●●●●'
                ></input>
                {errors.password_input && touched.password_input ? (
                  <div className='input_field_container__container_input_box__error_color'>
                    {errors.password_input}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='input_field_container'>
              <label
                className='input_field_container__label'
                htmlFor='confirmPassword_input_label'
              >
                Confirm password
              </label>
              <div className='input_field_container__container_input_box'>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='password'
                  className={
                    errors.confirmPassword_input &&
                    touched.confirmPassword_input
                      ? 'input_field_container__container_input_box__errors'
                      : 'input_field_container__container_input_box__input_field'
                  }
                  id='confirmPassword_input_label'
                  name='confirmPassword_input'
                  placeholder='●●●●●●●●'
                ></input>
                {errors.confirmPassword_input &&
                touched.confirmPassword_input ? (
                  <div className='input_field_container__container_input_box__error_color'>
                    {errors.confirmPassword_input}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='container_user_access_button'>
              <div className='container_user_access_button__setup'>
                <button
                  className={
                    !(isValid && dirty)
                      ? 'container_user_access_button__setup__disabled_button'
                      : 'container_user_access_button__setup__button'
                  }
                  type='submit'
                  disabled={!(isValid && dirty)}
                >
                  Continue{' '}
                  <span className='container_user_access_button__setup__arrowicon'>
                    <Images.smallArrowRight />
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserAccessSetup;

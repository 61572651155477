import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import './main-layout.scss';
import SideBar from '../components/SideBar/SideBar';
import { HiMenu } from 'react-icons/hi';
import { BankNavLogo } from '../assets/icons/sidebar';
import { BasicContext } from '../contextApi/BasicContext';
import { TEST_MODE, TEST__DEFAULT_MODE } from '../api/constants';
import { client } from '../api/api';
import { useSelector } from 'react-redux';
import NavigationBar from '../components/navigation_bar/NavigationBar';

const MainLayout = () => {
  const contextData = useContext(BasicContext);
  // const testMode = localStorage.getItem(TEST_MODE);
  const { testMode } = useSelector((state) => state.testmode);
  // const defaultTestMode = localStorage.getItem(TEST__DEFAULT_MODE);

  const handleClick = () => {
    document.body.classList.add('sidebar-open');
  };

  return (
    <div>
      <NavigationBar />
      <SideBar />
      <div className='main'>
        <div>
          {/* this outlet is used to navigate the pages which are given in the routes */}
          <div className='navbar__wrapper'>
            <div className='navbar__wrapper__icon'>
              <BankNavLogo.default />
            </div>

            <div className='navbar__wrapper__menu'>
              <HiMenu
                onClick={() => {
                  handleClick();
                }}
              />
            </div>
          </div>
          {testMode && (
            <div className={`main__sandbox`}>
              <p className='main__sandbox--text'>SANDBOX MODE</p>
            </div>
          )}
          <div className='main__content'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;

import React, { useState, useEffect } from 'react';
import AddPersonForm from '../CompanyOwn/AddPersonForm';
import { Images } from '../../assets';

const ContractOwner = ({ handleNext, handleBack }) => {
  const [showForm, setShowForm] = useState(false);

  const handleClick = () => {
    setShowForm(true);
  };

  const closeClick = () => {
    setShowForm(false);
    localStorage.removeItem('contracts_persons');
  };

  const checkOtherPerson= ()=>{
    let detail = localStorage.getItem('contracts_persons');
    if (detail != undefined && detail != null) {
        setShowForm(true);
    }
  }
  
  useEffect(() => {
    checkOtherPerson();
  }, []);
  
  const data = localStorage.getItem('clone_seller_members');
  const data_seller = {
    seller_data: JSON.parse(data),
  };

  const nextHandle = () => {
    handleNext();
  };

  return (
    <div className='Buisness_form_contract_name_container'>
      <div className='Buisness_form_contract_name_container__title'>
        Who signs contracts for <br></br> your company?
      </div>
      <div className='Buisness_form_contract_name_container__subtitle'>
        Please verify who is authorized to sign contracts on behalf of your
        <br></br>
        company to conclude your registration.
      </div>
      <div className='Buisness_form_contract_name_container__radio'>
        <input
          defaultChecked
          type='radio'
          htmlFor='Buisness_form_contract_name_container__radio__input'
          className='stakeholder_radio_input'
        ></input>
        <label
          className='Buisness_form_contract_name_container__radio__label'
          id='radio_input'
        >
          {`${data_seller.seller_data.first_name} ${data_seller.seller_data.last_name}`}
          , {data_seller.seller_data.address.address_1},{' '}
          {data_seller.seller_data.address.address_2},{' '}
          {data_seller.seller_data.address.city},{' '}
          {data_seller.seller_data.address.postcode}{' '}
        </label>
      </div>{' '}
      {showForm && (
        <div className='contain'>
          <img
            className='contain__close_image'
            src={Images.Closecross}
            onClick={closeClick}
          ></img>
        </div>
      )}
      {showForm && (
        <AddPersonForm origin="contracts" handleNext={handleNext} handleBack={handleBack} />
      )}
      {showForm === false ? (
        <div className='container_for_firstpage_nextback_leftAlign'>
          <button
            className='container_for_firstpage_nextback_leftAlign__addPerson'
            onClick={handleClick}
          >
            +Add Person
          </button>
          <button
            className='container_for_firstpage_nextback_leftAlign__back_button'
            onClick={handleBack}
          >
            Back
          </button>
          <button
            className='container_for_firstpage_nextback_leftAlign__submit_button'
            type='submit'
            onClick={nextHandle}
          >
            Next
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ContractOwner;

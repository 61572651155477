import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { ACCESS_TOKEN } from '../api/constants';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem(ACCESS_TOKEN);

  if (isAuthenticated) {
    return children || <Outlet />;
  }
  return <Navigate replace to='' />;
};

export default ProtectedRoute;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    created_at: '',
    email: '',
    first_name: '',
    last_name: '',
    merchant_user_id: '',
    role:''
};

const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    saveMerchant: (state, action) => {
      return {
        ...state,
        created_at: action.payload.merchant_user.created_at,
        email: action.payload.merchant_user.email,
        first_name: action.payload.merchant_user.first_name,
        last_name: action.payload.merchant_user.last_name,
        merchant_user_id: action.payload.merchant_user.merchant_user_id,
        role: action.payload.merchant_user.role
      };
    },
    clearMerchant: (state, action) => {
      return initialState;
    },
  },
});

export const { saveMerchant, clearMerchant } = merchantSlice.actions;
export default merchantSlice.reducer;
/**
 * Global Action File
 */

import { getMerchantDetails } from '../../api';
import { start } from '../slices/appSlice';
import { saveAuth, clearAuth } from '../slices/authSlice';
import { saveMerchant, clearMerchant } from '../slices/merchantSlice';
import { saveMerchantDetails } from '../slices/testmodeSlice';

export const appInit = (value) => (dispatch) => dispatch(start(value));
export const storeAuth = (value) => (dispatch) => dispatch(saveAuth(value));
export const storeMerchant = (value) => (dispatch) => dispatch(saveMerchant(value))
export const clearAuthData = (value) => (dispatch) =>
  dispatch(clearAuth(value));


import React, { useEffect } from 'react';
import './MerchantLoginPage.scss';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { Images } from '../../assets';
import { getMerchantDetails, loginAuthantication } from '../../api';
import { toast } from 'react-toastify';
import { localStorageItems, setMerchatApiKey, setMerchantDetails } from '../../utility/helper';
import { useDispatch } from 'react-redux';
import { saveAuth } from '../../state/slices/authSlice';
import { saveMerchant } from '../../state/slices/merchantSlice';
import {
  saveMerchantDetails,
  setLoading,
} from '../../state/slices/testmodeSlice';
import { ACCESS_TOKEN } from '../../api/constants';

const MerchantLoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    checkLogin();
  }, []);
  const checkLogin = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (accessToken) {
      navigate('/home');
    }
  }
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Email field is mandatory!'),
    password: Yup.string().required('Password field is mandatory!'),
  });
  const onSubmit = (values, actions) => {
    if (validationSchema) {
      values["email"] = String(values["email"]).toLowerCase()
      apiCall(values);
    }
  };
  const apiCall = async (values) => {
    const response = await loginAuthantication(values);
    if (response.status === 200) {
      localStorageItems(response);
      dispatch(saveAuth(response?.data?.data));
      await getMerchantData(response?.data?.data?.user?.merchant_user_id);
      navigate('/home');
    } else {
      toast.error('Invalid credentials');
    }
  };
  const getMerchantData = async (id) => {
    dispatch(setLoading(true));
    const response = await getMerchantDetails(id);
    dispatch(setLoading(false));
    if (response?.data?.code === 200) {
      setMerchatApiKey(response.data.data.account_details.api_key);
      setMerchantDetails(JSON.stringify(response.data.data.account_manager));
      dispatch(saveMerchant(response?.data?.data));
      dispatch(
        saveMerchantDetails(
          response?.data?.data?.account_details?.account_status
        )
      );
    } else if (response?.data?.code === 403) {
      dispatch(saveMerchantDetails('INREVIEW'));
    }
  };
  return (
    <div className='setup_access_user1'>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          values,
          dirty,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className='input_field_container1'>
              <div className='input_field_container1__container_input_box'>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email
                      ? 'input_field_container1__container_input_box__errors'
                      : 'input_field_container1__container_input_box__input_field'
                  }
                  id='email_input_label'
                  name='email'
                  placeholder='Email Address'
                  autoFocus={true}
                ></input>
                {errors.email && touched.email ? (
                  <div className='input_field_container1__container_input_box__error_color'>
                    {errors.email}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='input_field_container1'>
              <div className='input_field_container1__container_input_box'>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type='Password'
                  className={
                    errors.password && touched.password
                      ? 'input_field_container1__container_input_box__errors'
                      : 'input_field_container1__container_input_box__input_field'
                  }
                  id='password_input_label'
                  name='password'
                  placeholder='Password'
                ></input>
                {errors.password && touched.password ? (
                  <div className='input_field_container1__container_input_box__error_color'>
                    {errors.password}
                  </div>
                ) : null}
              </div>
            </div>
            {/* <div className='container_forget_password'>
              <div className='container_forget_password_link'>
                <a href='#' className='forget_password_link'>Forgot Password ?</a>
              </div>
            </div> */}
            <div className='container_user_access_button1'>
              <div className='container_user_access_button1__setup'>
                <button
                  className={
                    !(isValid && dirty)
                      ? 'container_user_access_button1__setup__disabled_button'
                      : 'container_user_access_button1__setup__button'
                  }
                  type='submit'
                  disabled={!(isValid && dirty)}
                >
                  Log In{' '}
                  <span className='container_user_access_button1__setup__arrowicon'>
                  </span>
                </button>
              </div>
            </div>
            <div className='container_new_user'>
              <div className='container_new_user_link'>
                <span className='new_user_span'>New to Fam?
                </span>
                <a href={`mailto:${'sales@joinfam.com'}`} className='new_user_link'>Contact sales</a>
              </div>
            </div>
            <div className='container_policy'>
              <div className='container_policy_link'>
                <span className='policy_span'>By clicking “Log In” above, you acknowledge that you have read and understood, and agree to Fam’s <a href='#' className='service_link'>Terms of Service</a> and <a href='#' className='policy_link'>Privacy Policy</a></span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default MerchantLoginPage;

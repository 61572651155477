import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Stepper, Step } from '@mui/material';
import NavigationBar from '../../components/navigation_bar/NavigationBar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import getImageContent from '../../components/contentfirstbuissnessform/imagecontentfirstbuissnessform';
import getStepContent from '../../components/contentfirstbuissnessform/stepContentforbuissness1';
import { useStyles } from './firstform_styles';

const theme = createTheme({
  breakpoints: {
    values: {
      phone: 320,
      tablet: 768,
      'small-desktop': 1024,
      'mid-desktop': 1280,
      LGdesktop: 1920,
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
}));

const steps = ['Buisness', 'Stakeholders', 'Bank Accounts'];

function BuisnessSignup1() {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  let [counter, setCounter] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const className = classes.img_buisness_signup_page;

  const largeScreen = useMediaQuery(
    theme.breakpoints.between('phone', 'small-desktop')
  );
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    setCounter((counter) => {
      counter = counter + 1;
      return counter;
    });

    if (counter === 0) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
    if (counter === 3) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setCounter((counterStep) => {
      counterStep = counterStep - 1;
      return counterStep;
    });
    if (counter === 5 || counter === 2) {
      setActiveStep((activeStepno) => {
        activeStepno = activeStepno - 1;
        return activeStepno;
      });
    }
  };

  return (
    <Box sx={{ width: '100%' }} className={classes.root}>
      <NavigationBar></NavigationBar>
      <Grid
        direction={largeScreen ? 'column' : 'row'}
        container
        className={classes.buisnessSignup_grid_container}
      >
        <Item className={classes.buisnessSignup_item_container}>
          <Stepper
            className={classes.stepper_container}
            connector={<></>}
            activeStep={activeStep}
          >
            {steps.map((label, index) => (
              <Step
                className={
                  activeStep >= index
                    ? classes.steps_container_active
                    : classes.steps_container_disable
                }
                key={index}
                completed={completed[index]}
              >
                {label}
              </Step>
            ))}
          </Stepper>
          <div>
            <React.Fragment>
              {getStepContent(counter, handleNext, handleBack)}
            </React.Fragment>
          </div>
        </Item>
        <Item
          width={largeScreen ? '100%' : '50%'}
          className={classes.buisnessSignup_item_container}
        >
          {getImageContent(counter, className)}
        </Item>
      </Grid>
    </Box>
  );
}

export default BuisnessSignup1;

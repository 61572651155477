import React from 'react';
import './UserSignup.scss';
import NavigationBar from '../../components/navigation_bar/NavigationBar';
import UserAccessSetup from '../../components/UserAccessSetup/UserAccessSetup';

const UserSignup = () => {
  return (
    <div>
      <div>
        <NavigationBar></NavigationBar>
      </div>
      <div className='userSignup_access_form'>Get started with Fam</div>
      <div className='setup_access'>
        <UserAccessSetup />
      </div>
    </div>
  );
};

export default UserSignup;

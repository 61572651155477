import React from 'react';
import BuisnessSignupform1_2 from '../../components/buisnessSignup1stform/Signupform1_2';
import BuisnessSignupform1_4 from '../../components/buisnessSignup1stform/Signupform1_4ceo';
import StakeholderRadio from '../../components/Stakeholder1/StakeholderRadio';
import ContractOwner from '../../components/contractOwner/ContractOwner';
import BankAccountPage from '../../components/BankAccountPage/BankAccountPage';

export default function getStepContent(counter, handleNext, handleBack) {
  switch (counter) {
    case 0:
      return (
        <>
          <BuisnessSignupform1_2
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </>
      );
      break;
    case 1:
      return (
        <>
          <StakeholderRadio handleNext={handleNext} handleBack={handleBack} />
        </>
      );
      break;
    case 2:
      return (
        <>
          <BuisnessSignupform1_4
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </>
      );
      break;
    case 3:
      return (
        <>
          <ContractOwner handleNext={handleNext} handleBack={handleBack} />
        </>
      );
      break;
    case 4:
      return (
        <>
          <BankAccountPage handleNext={handleNext} handleBack={handleBack} />
        </>
      );
      break;
  }
}

import React, { createContext, useState } from 'react';

export const BasicContext = createContext();

export const BasicContextProvider = ({ children }) => {
  const [testMode, setTestMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sellerNotApproved, setSellerNotApproved] = useState(false);
  const [curentDate, setCurrentDate] = useState('');
  const [storeNameChange, setStoreNameChange] = useState(false);

  return (
    <BasicContext.Provider
      value={{
        testMode: testMode,
        setTestMode: setTestMode,
        loading: loading,
        setLoading: setLoading,
        sellerNotApproved: sellerNotApproved,
        setSellerNotApproved: setSellerNotApproved,
        setCurrentDate: setCurrentDate,
        curentDate: curentDate,
        storeNameChange,
        setStoreNameChange,
      }}
    >
      {children}
    </BasicContext.Provider>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import './sidebar.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SideBarNav from './sidebarContent';
import { CgClose } from 'react-icons/cg';
import { Button } from 'react-bootstrap';
import { HiDotsHorizontal } from 'react-icons/hi';
import { BankLogo } from '../../assets';
import { BsArrowUpRight } from 'react-icons/bs';
import {
  BRAND_NAME,
  FIRST_NAME,
  LAST_NAME,
  TEST_MODE,
} from '../../api/constants';
import { client, prodUrl, sandboxUrl, nonSandboxClient } from '../../api/api';
import { BasicContext } from '../../contextApi/BasicContext';
import { toast } from 'react-toastify';
import { getMerchantDetails, getPublicInfo } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuth } from '../../state/slices/authSlice';
import {
  clearMerchantDetails,
  saveMerchantDetails,
  setTestMode,
} from '../../state/slices/testmodeSlice';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { setMerchatApiKey } from '../../utility/helper';

const SideBar = () => {
        
    
  const [optionMenu, setOptionMenu] = useState(null);
  const menuOpen = Boolean(optionMenu);
  const handleMenuClick = (event) => {
    setOptionMenu(event.currentTarget);
  };
  const handleMenuClose = () => {
    setOptionMenu(null);
  };
  
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const testmode = useSelector((state) => state.testmode);
  const [mode, setMode] = useState(testmode.testMode);
  const contextData = useContext(BasicContext);
  const [activeItem, setActiveItem] = useState(null);
  let storeName = localStorage.getItem(BRAND_NAME);
  
  if(storeName == 'null'){
    storeName = ''
  }

  useEffect(() => {
    const curPath = window.location.pathname.split('/')[1];
    SideBarNav.filter((item, ind) => {
      if (item.section === curPath) {
        updateRoute(SideBarNav[ind].id);
      } else if (item.children && item.section !== curPath) {
        item.children.filter((itemChild, index) => {
          if (itemChild.section === curPath) {
            updateRoute(SideBarNav[ind].children[index].id);
          }
        });
      }
    });    
  }, [location]);

  useEffect(() => {
    setMode(testmode.testMode)
    if (testmode.testMode === true) {
      client.defaults.baseURL = sandboxUrl;
    } else {
      client.defaults.baseURL = prodUrl;
    }
    nonSandboxClient.defaults.baseURL = prodUrl;
  }, [testmode.testMode]);

  useEffect(() => {
    getBrandName();
  }, [contextData.storeNameChange]);

  const getBrandName = async () => {
    const storeName = localStorage.getItem(BRAND_NAME);
    if (!storeName || contextData.storeNameChange) {
      const response = await getPublicInfo();
      localStorage.setItem(BRAND_NAME, response?.data?.data?.brand_name);
    }
  };

  
  const handleLogout = () => {
    client.defaults.baseURL = prodUrl;
    localStorage.clear();
    dispatch(clearAuth());
    dispatch(clearMerchantDetails());
    navigate('');
  };

  const updateRoute = (path) => {
    setActiveItem(path);
  };

  const handleClose = () => {
    document.body.classList.remove('sidebar-open');
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  
  const handleSwitch = (e) => {
    setMode(e.target.checked)
    if (e.target.checked === true) {
      sandboxMerchantDetails()      
      return null;
    } else {
        toast('Now viewing Live data');
        liveMerchantDetails();
        return null;
    }
  };
  
  const sandboxMerchantDetails = async () => {
    client.defaults.baseURL = sandboxUrl;
    const merchantUserId = localStorage.getItem('merchant_user_id');
    const response = await getMerchantDetails(merchantUserId);
    if (response.data.code === 200) {
        setMerchatApiKey(response.data.data.account_details.api_key);
        toast('Now viewing Sandbox data');
        dispatch(setTestMode(true));
    }else{
        toast.error('something went wrong please try again');
        client.defaults.baseURL = prodUrl;
    }
  }

  const liveMerchantDetails = async () => {
    client.defaults.baseURL = prodUrl;
    const merchantUserId = localStorage.getItem('merchant_user_id');
    const response = await getMerchantDetails(merchantUserId);
    if (response.data.code === 200) {
      const { account_status } = response?.data?.data?.account_details;
      dispatch(saveMerchantDetails(account_status));
      if (account_status !== 'INREVIEW') {
        dispatch(setTestMode(false));
        setMerchatApiKey(response.data.data.account_details.api_key);
      } else {
        client.defaults.baseURL = sandboxUrl;
        dispatch(setTestMode(true));
        setMode(true)
      }
    } else if (response?.data?.code === 403) {
      client.defaults.baseURL = sandboxUrl;
      dispatch(saveMerchantDetails('INREVIEW'));
      setMode(true)
      toast.error('Merchant is not yet approved');
    }
  };

  return (
    <div className='sidebar'>
      <div className='sidebar__wrapper'>
        <div className='sidebar__wrapper--top'>
          

          

          <div className='sidebar__menu'>
            {SideBarNav?.map((navitem) => {
              return (
                <>
                  <div key={navitem.uuid} className={`sidebar__menu__item `}>
                    {navitem.icon || navitem.activeIcon ? (
                      <div className='sidebar__menu__item__icon'>
                          {
                            activeItem === navitem.id ||
                            navitem?.childList?.includes(activeItem)
                              ? navitem.activeIcon
                              : navitem.icon
                          }
                      </div>
                    ) : (
                      ''
                    )}
                    {
                        navitem.uuid == 23 ? (
                            <a href='https://joinfam.readme.io' target='_blank' className={`sidebar__menu__item__txt`}>
                                {navitem.text}{' '}                                
                                {navitem?.dev === true && <BsArrowUpRight />}
                            </a>
                        ) : (
                            <Link to={navitem.link}
                                className={`sidebar__menu__item__txt ${
                                    activeItem === navitem.id ||
                                    navitem?.childList?.includes(activeItem)
                                    ? 'active'
                                    : ''
                                }`} onClick={handleClose}>
                                {navitem.text}{' '}
                                {navitem?.dev === true && <BsArrowUpRight />}
                            </Link>
                        )
                    }                    
                  </div>
                  {(navitem?.children?.length > 0 &&
                    activeItem === navitem.id) ||
                  (Math.floor(activeItem) === navitem.id &&
                    navitem?.children?.length > 0)
                    ? navitem.children.map((navChild) => {
                        return (
                          <div
                            key={navChild.uuid}
                            className={`sidebar__menu__item`}
                          >
                            <div className='sidebar__menu__item__icon'></div>
                            <Link
                              to={navChild.link}
                              className={`sidebar__menu__item__txt  ${
                                activeItem === navChild.id ? 'active' : ''
                              }`}
                              onClick={handleClose}
                            >
                              {navChild.text}
                            </Link>
                          </div>
                        );
                      })
                    : ''}
                </>
              );
            })}
          </div>
        </div>
        {/* <Link
          to='/developer'
          className={`sidebar__menu__item ${activeItem==9 ? 'active'  : ''}`}
        >
          <div className='sidebar__menu__item__txt' onClick={handleClose}>
            Developer hub <BsArrowUpRight />
          </div>
        </Link> */}

        <div className='sidebar__menu__bottom'>
          <div className='sidebar__menu__bottom__txt'>
            <div className='sidebar__menu__bottom__txt--left'>
              <p className=''>Test mode</p>
              <input
                type='checkbox'
                id='switch'
                disabled={testmode.defaultTestMode}
                onChange={handleSwitch}
                checked={mode}
              />
              <label htmlFor='switch'></label>
            </div>
          </div>
          <div className='sidebar__menu__bottom__txt'>
             
            <Menu
                anchorEl={optionMenu}
                open={menuOpen}
                onClose={handleMenuClose}   
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}             
                >
                <MenuItem className='logout' onClick={handleLogout}>Log out</MenuItem>                
            </Menu>
            <div className='sidebar__menu__bottom__txt--left'>
              <span className=''>
                {auth?.first_name}&nbsp;{auth?.last_name}
              </span>
              <div className='margin__right'>
                <HiDotsHorizontal onClick={handleMenuClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;

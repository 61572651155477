// export * as Logo from './logo.png';
// export * as RightArrowShort from './arrow-right.svg';
import * as firstPagePicture from './FirstPagePicture.png';
import * as logoImage from './logo.png';
import * as vectorImage from './navigation-vector.png';
import * as SecondPageImage from './SecondPageImage.png';
import * as ShopifyLogo from './ShopifyLogo.svg';
import * as AdobeCommerceLogo from './AdobeCommerceLogo.svg';
import * as Chevron_Right from './Chevron_Right.svg';
import * as ThirdpageImage from './ThirdpageImage.png';
import * as FourthSeeYoupage from './Fourthseeyou.png';
import * as BuisnessSignup1 from './buisness-signup1.png';
import * as BuissnessSignup1_2 from './buisnessSignup1_2.png';
import * as OwnerCompany from './ownerCompany.png';
import * as BankPage from './BankPage.png';
import * as ContractSign from './ContractSignee.png';
import * as BuissnessOwns from './BuisnessOwns.png';
import * as smallArrowRight from './smallArrowRight.svg';
import * as UserSetupLoadingBackground from './UserSetupLoadingBackground.png';
import * as smallClock from './smallclock.png';
import * as reportActive from './report-active.png';
import * as starActive from './star-active.png';
export * as BankLogo from './fam.svg';
export * as DownArrow from './Vector.svg';
export * as Store from './store-settings.svg';
export * as StoreDark from './about-dark.svg';
import * as logoforOther from './logoforOther.svg';
import * as salesforceLogo1 from './salesForceLogo1.svg';
import * as customLogo from './customLogo.svg';
import * as ukflag from './ukflag.png';
import * as smallArrowLeft from './smallArrowLeft.svg';
import * as Closecross from './Close.png';
export * as SiteWideLight from './SitewideDark.svg';
export * as SiteWideDark from './SitewideWhite.svg';
export * as DarkWhite from './dark-white.svg';
export * as DarkBlack from './dark-black.svg';
export * as DarkYellow from './dark-yellow.svg';
export * as LightBlack from './light-black.svg';
export * as LightWhite from './light-white.svg';
export * as LightYellow from './light-yellow.svg';
export * as SearchIcon from './search 1.svg';
export * as BrandingImg from './brandingLogo.svg';
export * as BrandingStore from './brandingStore.svg';

export const Images = {
  firstPagePicture: firstPagePicture.default,
  logoImage: logoImage.default,
  vectorImage: vectorImage.default,
  SecondPageImage: SecondPageImage.default,
  ShopifyLogo: ShopifyLogo.default,
  AdobeCommerceLogo: AdobeCommerceLogo.default,
  Chevron_Right: Chevron_Right.default,
  ThirdpageImage: ThirdpageImage.default,
  FourthSeeYoupage: FourthSeeYoupage.default,
  BuisnessSignup1: BuisnessSignup1.default,
  BuissnessSignup1_2: BuissnessSignup1_2.default,
  OwnerCompany: OwnerCompany.default,
  BankPage: BankPage.default,
  ContractSign: ContractSign.default,
  BuissnessOwns: BuissnessOwns.default,
  smallArrowRight: smallArrowRight.default,
  UserSetupLoadingBackground: UserSetupLoadingBackground.default,
  smallClock: smallClock.default,
  salesforceLogo1: salesforceLogo1.default,
  customLogo: customLogo.default,
  logoforOther: logoforOther.default,
  ukflag: ukflag.default,
  smallArrowLeft: smallArrowLeft.default,
  Closecross: Closecross.default,
};

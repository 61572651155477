import React, { useState, useEffect } from 'react';
import './SetupLoading.scss';
import NavigationBar from '../../components/navigation_bar/NavigationBar';
import { Images } from '../../assets';
import UserSetupLoadingBackground from '../../assets/UserSetupLoadingBackground.png';
import { useNavigate } from 'react-router';
import { ACCESS_TOKEN } from '../../api/constants';
import { updateOnBordingStatus } from '../../api';
const SetupLoading = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    updateOnBoardingStatus();
  }, []);

  const updateOnBoardingStatus = async () => {
    const request = {
      uuid: localStorage.getItem('on_boarding_id'),
      status: 'COMPLETED',
      merchant: localStorage.getItem('merchant_pk')
    }
    const response = await updateOnBordingStatus(request);
    if (response.status === 200) {
      localStorage.removeItem('on_boarding_id');
      if (localStorage.getItem(ACCESS_TOKEN)) {
        setIsLoading(false);
      }
    }
  }

  return (
    <div>
      {isLoading === true ? (
        <>
          <div>
            <NavigationBar />
          </div>
          <div
            className='loading_background__image'
            style={{ backgroundImage: `url(${UserSetupLoadingBackground})` }}
          >
            <div className='loading_background__content'>
              YOU'RE ALL SET.
              <p>WELCOME TO BETTER ECOMMERCE.</p>
              <p className='loading_background__setting'>
                <img
                  className='loading_background__setting__small_clock'
                  src={Images.smallClock}
                ></img>
                setting up account...
              </p>
            </div>
          </div>
        </>
      ) : (
        navigate('/home')
      )}
    </div>
  );
};

export default SetupLoading;

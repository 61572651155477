/**
 * Global API file for all API calls
 */

import axios from "axios";
import http, { httpWithoutHeaders } from "./api";
import Axios, { client, nonSandboxClient } from "./api";
import { MERCHANT_ID, MERCHANT_APIKEY } from "../api/constants";

export const createCompaniesAPI = async (data) => {
  try {
    var config = {
      method: "post",
      url: "https://api.hubapi.com/crm/v3/objects/companies",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer pat-eu1-b26430db-5dfc-4399-8ece-91a8ab77c33a",
        mode: "no-cors",
      },
      data: data,
      "Access-Control-Allow-Origin": "*",
    };
    const d = await axios(config);
    console.log("checking", d);
    return d;
  } catch (e) {
    console.log("this is error ", e);
    return e;
  }
};

const defaultPage = 25;

export const callSomeAPI = async () => {
  try {
    const response = await Axios.get("/some_route");
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const dashBoardDetails = async (from_date, to_date) => {
  try {
    const response = await client.get(
      `/api/v1/merchant/dashboard/?from_date=${from_date}&to_date=${to_date}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const dashBoardGraphDetails = async (from_date, to_date) => {
  try {
    const response = await client.get(
      `/api/v1/merchant/dashboard-graph/?from_date=${from_date}&to_date=${to_date}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const getCategoryValues = async () => {
  try {
    const categoryValues = await nonSandboxClient.get(
      `/v1/payment/categories/`
    );
    return categoryValues;
  } catch (error) {
    return error.response;
  }
};

export const getMerchantDetails = async (id) => {
  try {
    const response = await client.get(`/api/v1/merchant/user/${id}/`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const loginAuthantication = async (email) => {
  try {
    const login = await httpWithoutHeaders.post(
      `/api/v1/merchant/login/`,
      email
    );

    return login;
  } catch (error) {
    return error.response;
  }
};

export const verifyOtp = async (data) => {
  try {
    const otp = await httpWithoutHeaders.post(`/api/v1/login/`, data);
    return otp;
  } catch (error) {
    return error;
  }
};

export const signUpAuthentication = async (data) => {
  try {
    const signup = await httpWithoutHeaders.post(
      `/api/v1/merchant/user/login/`,
      data
    );
    return signup;
  } catch (error) {
    return error.response;
  }
};

export const otpAuthentication = async (data) => {
  try {
    const otp = await httpWithoutHeaders.post(`/api/v1/login/`, data);
    return otp;
  } catch (error) {
    return error.response;
  }
};

export const validateOnboarding = async (id) => {
  try {
    const status = await httpWithoutHeaders.get(
      `/api/v1/merchant/onboarding-url?uuid=${id}`
    );
    return status;
  } catch (error) {
    return error.response;
  }
};

export const signUpOnboard = async (data) => {
  try {
    const signup = await httpWithoutHeaders.post(
      `/api/v1/merchant/user/signup/`,
      data
    );
    return signup;
  } catch (error) {
    return error.response;
  }
};

// ********************************************************
export const storeCreditRewards = async (data) => {
  try {
    const store = await client.put(
      `api/v1/merchant/store-credit-rewards`,
      data
    );
    return store;
  } catch (error) {
    return alert(error);
  }
};

export const merchantOnBoard = async (data) => {
  try {
    const merchantData = await httpWithoutHeaders.post(
      `api/v1/merchant/merchant-onboarding/`,
      data
    );
    return merchantData;
  } catch (error) {
    return error;
  }
};
// ********************************************************

export const payoutAllDetails = async (pageNumber, download) => {
  try {
    const response = await client.get(
      `/api/v1/merchant/payouts/all?${download == true
        ? "export=true&report_type=csv"
        : `page=${pageNumber}&limit=${defaultPage}`
      }`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const payoutBalance = async () => {
  try {
    const response = await client.get("/api/v1/merchant/balances");
    return response;
  } catch (error) {
    return error.response;
  }
};

export const payoutAddFunds = async () => {
  try {
    const response = await client.get(
      "/api/v1/merchant/nuapay-account-detail/"
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const payoutAddFundsDetails = async () => {
  try {
    const response = await client.get(
      "/api/v1/merchant/payment-account-detail/"
    );
    return response;
  } catch (error) {
    return error.response;
  }
};


export const individiualPayoutDetails = async (payout_id) => {
  try {
    const response = await client.get(`/api/v1/merchant/payouts/${payout_id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const OrdersAllDetails = async (
  pageNumber,
  fromDate,
  toDate,
  search = "",
  download
) => {
  try {
    const response = await client.get(
      `/api/v1/merchant/customers-orders?status=COMPLETED,REFUND,PARTIAL_REFUND&from_date=${fromDate}&to_date=${toDate}&search=${search}&${download === true
        ? "export=true&report_type=csv"
        : `page=${pageNumber}&limit=${defaultPage}`
      }`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const individiualOrdersDetails = async (orderId) => {
  try {
    const config = {
      headers: {
        "MERCHANT-ID": localStorage.getItem(MERCHANT_ID),
        "API-SECRET": localStorage.getItem(MERCHANT_APIKEY),
      },
    };
    console.log("config", config);
    const response = await client.get(
      `/v1/orders/${orderId}?type=ecommerce`,
      config
    );
    return response;
  } catch (error) {
    console.log("erororo", error);
    return error.response;
  }
};

export const sellerDetails = async (orderId) => {
  try {
    const response = await client.get(
      `/api/v1/merchant/user/${localStorage.getItem(MERCHANT_USER_ID)}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const refundTransaction = async (orderId, refundDetails) => {
  try {
    const response = await client.post(
      `/v1/orders/${orderId}/refund/`,
      refundDetails,
      {
        headers: {
          "MERCHANT-ID": localStorage.getItem(MERCHANT_ID),
          "API-SECRET": localStorage.getItem(MERCHANT_APIKEY),
        },
      }
    );
    return response;
  } catch (error) {
    console.log("");
    return error.response;
  }
};

export const merchantTranscationDetails = async (
  from_date,
  to_date,
  timezone = "",
  download = false
) => {
  try {
    let response = '';
    if (download) {
      response = await client.get(
        `/api/v1/merchant/master-report/?from_date=${from_date}&to_date=${to_date}&timezone=${timezone}&export=true&report_type=csv&type=transaction,refund_deduction`
      );
    } else {
      response = await client.get(
        `/api/v1/merchant/merchant-transaction-report/?from_date=${from_date}&to_date=${to_date}&timezone=${timezone}${download === true ? `&export=true&report_type=csv` : ''
        }`
      );
    }

    download === true && `&export=true&report_type=csv`;
    return response;
  } catch (error) {
    return error.response;
  }
};

export const storeCreditDetails = async (
  page,
  from_date,
  to_date,
  timezone = "",
  download = false
) => {
  try {
    let response = '';
    if (download) {
      response = await client.get(
        `/api/v1/merchant/master-report/?from_date=${from_date}&to_date=${to_date}&timezone=${timezone}&export=true&report_type=csv&type=store_credit`
      );
    } else {
      response = await client.get(
        `/api/v1/merchant/store-credits-list/?page=${page}&limit=${defaultPage}&from_date=${from_date}&to_date=${to_date}&timezone=${timezone}${download === true ? `&export=true&report_type=csv` : ''
        }`
      );
    }
    return response;
  } catch (error) {
    return error.response;
  }
};

export const storeCreditActions = async () => {
  try {
    const response = await client.get(
      `/api/v1/merchant/store-credits-actions/`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const payoutReconciliationDetails = async (
  from_date,
  to_date,
  timezone = "",
  download = false
) => {
  try {
    const response = await client.get(
      `/api/v1/merchant/payout-reconciliation-report?from_date=${from_date}&to_date=${to_date}&timezone=${timezone}${download === true ? `&export=true&report_type=csv` : ""
      }`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const customersAllDetails = async (page_number) => {
  try {
    const response = await client.get(
      `/api/v1/merchant/customers?page=${page_number}&limit=${defaultPage}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const downloadCustomersDetails = async () => {
  try {
    const response = await client.get(
      `/api/v1/merchant/customers?export=true&report_type=csv`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const individiualCustomersDetails = async (customerId, page_number) => {
  try {
    const response = await client.get(
      `/api/v1/merchant/customer-detail/${customerId}?page=${page_number}&limit=${defaultPage}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const downloadIndividiualCustomerDetails = async (customerId) => {
  try {
    const response = await client.get(
      `/api/v1/merchant/customer-detail/${customerId}?export=true&report_type=csv`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getAccountDetails = async () => {
  try {
    const response = await nonSandboxClient.get(`/api/v1/merchant/profile/account/`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateAccountDetails = async (updatedDetails) => {
  try {
    const response = await nonSandboxClient.put(
      `/api/v1/merchant/profile/account/`,
      updatedDetails
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getUserLoginDetails = async () => {
  try {
    const response = await nonSandboxClient.get(
      `/api/v1/merchant/profile/login-information/`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateUserLoginDetails = async (updatedDetails) => {
  try {
    const response = await nonSandboxClient.put(
      `/api/v1/merchant/profile/login-information/`,
      updatedDetails
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateUserPassword = async (updatedDetails) => {
  try {
    const response = await nonSandboxClient.post(
      `/api/v1/merchant/change-password/`,
      updatedDetails
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPublicInfo = async () => {
  try {
    const response = await nonSandboxClient.get(
      `/api/v1/merchant/profile/public-information/`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updatePublicInfo = async (updatedDetails) => {
  try {
    const response = await nonSandboxClient.put(
      `/api/v1/merchant/profile/public-information/`,
      updatedDetails
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getBussinessInfo = async () => {
  try {
    const response = await nonSandboxClient.get(
      `/api/v1/merchant/profile/business-tax-information/`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getIndustryTypes = async () => {
  try {
    const response = await client.get(`/v1/payment/categories/`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateBussinessInfo = async (updatedDetails) => {
  try {
    const response = await nonSandboxClient.put(
      `/api/v1/merchant/profile/business-tax-information/`,
      updatedDetails
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getManagementDetails = async () => {
  try {
    const response = await client.get(
      `/api/v1/merchant/profile/merchant-members/`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getCompanyDirectorsDetails = async (designation) => {
  try {
    const response = await client.get(
      `/api/v1/merchant/profile/merchant-members/`,
      {
        params: {
          designation: designation,
          is_owner: false,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateCompanyDirectorsDetails = async (updatedData) => {
  try {
    const response = await client.post(
      `/api/v1/merchant/profile/merchant-members/`,
      updatedData
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getBankAccountDetails = async () => {
  try {
    const response = await client.get(`/api/v1/merchant/profile/bank-account/`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getWebHooktDetails = async () => {
  try {
    const response = await client.get(
      `/api/v1/merchant/profile/webhook-details`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateWebHooktDetails = async (updatedData) => {
  try {
    const response = await client.put(
      `/api/v1/merchant/profile/webhook-details`,
      updatedData
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getBrandingInformation = async () => {
  try {
    const response = await client.get(`/api/v1/merchant/profile/branding`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateBrandingInformation = async (data) => {
  try {
    const response = await client.put(
      `/api/v1/merchant/profile/branding`,
      data
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateOnBordingStatus = async (data) => {
  try {
    const response = await httpWithoutHeaders.put(
      `/api/v1/merchant/onboarding-url`,
      data
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const uploadDocuments = async (data) => {
  try {
    const documentsData = await httpWithoutHeaders.post(
      `api/v1/merchant/upload_documents`,
      data
    );
    return documentsData;
  } catch (error) {
    return error;
  }
};

import StakeholderRadio from '../../components/Stakeholder1/StakeholderRadio';
import ContractOwner from '../../components/contractOwner/ContractOwner';
import BankAccountPage from '../../components/BankAccountPage/BankAccountPage';
import BuisnessSignupform2_22 from '../buisnessSignup2ndform/buisnessSignup2nd';
import BuisnessSignupform2_2 from '../buisnessSignup2ndform/Signupform2_2';
import React from 'react';

export default function getStepContentform2(
  step,
  counter,
  handleNext,
  handleBack
) {
  switch (counter) {
    case 0:
      return (
        <>
          <BuisnessSignupform2_22
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </>
      );
      break;
    case 1:
      return (
        <>
          <StakeholderRadio handleNext={handleNext} handleBack={handleBack} />
        </>
      );
      break;
    case 2:
      return (
        <>
          <BuisnessSignupform2_2
            handleNext={handleNext}
            handleBack={handleBack}
          />
        </>
      );
      break;
    case 3:
      return (
        <>
          <ContractOwner handleNext={handleNext} handleBack={handleBack} />
        </>
      );
      break;
    case 4:
      return (
        <>
          <BankAccountPage handleNext={handleNext} handleBack={handleBack} />
        </>
      );
      break;
  }
}

import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../../components/input_field/Input";
import { useNavigate } from "react-router";
import { getMerchantDetails, signUpOnboard } from "../../api";
import { localStorageItems } from "../../utility/helper";
import { saveAuth } from "../../state/slices/authSlice";
import { useDispatch } from "react-redux";
import { getMerchantData } from "../../state/action/action";
import {
  saveMerchantDetails,
  setLoading,
} from "../../state/slices/testmodeSlice";

const BankAccountPage = ({ handleNext, handleBack }) => {
  // const nameReg = /^[a-zA-Z]*$/;
  const sortCode_regex = /^[0-9]{2}[0-9]{2}[0-9]{2}$/;
  const accNum_regex = /^[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}$/;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  const validationSchema = Yup.object().shape({
    account_name: Yup.string()
      // .matches(nameReg, 'Please enter a valid account name')
      // .max(25, 'You can only enter 25 letters')
      .required(""),
    sort_code: Yup.string()
      .matches(sortCode_regex, "Please enter a valid sort code")
      .required(""),
    account_number: Yup.string()
      .matches(accNum_regex, "Please enter a valid account number")
      .required(""),
  });

  const onSubmit = (values, { setSubmitting }) => {
    if (validationSchema) {
      const postAPI = {
        account_name: values.account_name,
        branch_code: values.sort_code,
        account_number: values.account_number,
      };
      localStorage.setItem("bank_details", JSON.stringify(postAPI));
      const login_info = localStorage.getItem("login_info");
      const store_details = localStorage.getItem("store_details");
      const company_info = localStorage.getItem("company_info");
      const bank_details = localStorage.getItem("bank_details");
      const key_persons = localStorage.getItem("key_persons");
      const contracts_person = localStorage.getItem("contracts_persons");
      let lastData = {
        login_info: JSON.parse(login_info),
        store_details: JSON.parse(store_details),
        company_info: JSON.parse(company_info),
        bank_details: JSON.parse(bank_details),
        key_persons: JSON.parse(key_persons),
      };
      if (contracts_person != "" && contracts_person != null) {
        lastData["key_persons"] = [
          ...lastData["key_persons"],
          JSON.parse(contracts_person),
        ];
      }
      apiCall1(lastData);
    }
  };
  const apiCall1 = async (values) => {
    const response = await signUpOnboard(values);
    if (response?.status === 201) {
      localStorageItems(response);
      dispatch(saveAuth(response?.data?.data));
      getMerchantData(response?.data?.data?.user?.merchant_user_id);
      localStorage.setItem(
        "merchant_pk",
        response?.data?.data?.user?.merchant?.merchant_pk
      );
      localStorage.removeItem("login_info");
      localStorage.removeItem("store_details");
      localStorage.removeItem("company_info");
      localStorage.removeItem("bank_details");
      localStorage.removeItem("key_persons");
      localStorage.removeItem("clone_seller_members");
      localStorage.removeItem("contracts_persons");
      localStorage.removeItem("stakeholder");
      navigate("/loading");
    }
  };

  const getMerchantData = async (id) => {
    dispatch(setLoading(true));
    const response = await getMerchantDetails(id);
    dispatch(setLoading(false));
    if (response?.data?.code === 200) {
      dispatch(
        saveMerchantDetails(
          response?.data?.data?.account_details?.account_status
        )
      );
    } else if (response?.data?.code === 403) {
      dispatch(saveMerchantDetails("INREVIEW"));
    }
  };

  return (
    <div className="bankaccount_SignupForm_Container">
      <div className="bankaccount_SignupForm_Container__content">
        <h4 className="bankaccount_SignupForm_Container__content__title">
          Where would you like to <br></br> get paid?
        </h4>
        <p className="bankaccount_SignupForm_Container__content__subtitle">
          Final step - give us your bank details so we know where to pay you.
        </p>
      </div>
      <div className="bankaccount_SignupForm_Container__bank_account_form_container">
        <Formik
          initialValues={{
            account_name: "",
            sort_code: "",
            account_number: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
            handleBlur,
            dirty,
            isValid,
            isSubmitting,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className="bankaccount_signup_first_field1">
                  <label
                    className="bankaccount_signup_first_field1__label"
                    htmlFor="account_name_buisnessSignup1"
                  >
                    Account Name
                  </label>
                  <div className="bankaccount_signup_first_field1__input">
                    <InputField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Demo Shop Ltd"
                      className={
                        errors.account_name && touched.account_name
                          ? "bankaccount_signup_first_field1__input__error"
                          : "bankaccount_signup_first_field1__input__field"
                      }
                      id="account_name_buisnessSignup1"
                      name="account_name"
                    ></InputField>
                    {errors.account_name && touched.account_name ? (
                      <div className="bankaccount_signup_first_field1__error_color">
                        {errors.account_name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="bankaccount_signup_first_field1">
                  <label
                    className="bankaccount_signup_first_field1__label"
                    htmlFor="sort_code_buisnessSignup1"
                  >
                    Sort code
                  </label>
                  <div className="bankaccount_signup_first_field1__input">
                    <InputField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="40-10-100"
                      className={
                        errors.sort_code && touched.sort_code
                          ? "bankaccount_signup_first_field1__input__error"
                          : "bankaccount_signup_first_field1__input__field"
                      }
                      id="sort_code_buisnessSignup1"
                      name="sort_code"
                    ></InputField>
                    {errors.sort_code && touched.sort_code ? (
                      <div className="bankaccount_signup_first_field1__error_color">
                        {errors.sort_code}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="bankaccount_signup_first_field1">
                  <label
                    className="bankaccount_signup_first_field1__label"
                    htmlFor="account_number_buisnessSignup1"
                  >
                    Account number
                  </label>
                  <div className="bankaccount_signup_first_field1__input">
                    <InputField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="12345678"
                      className={
                        errors.account_number && touched.account_number
                          ? "bankaccount_signup_first_field1__input__error"
                          : "bankaccount_signup_first_field1__input__field"
                      }
                      id="account_number_buisnessSignup1"
                      name="account_number"
                    ></InputField>
                    {errors.account_number && touched.account_number ? (
                      <div className="bankaccount_signup_first_field1__error_color">
                        {errors.account_number}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="container_for_nextback1">
                  <button
                    className="container_for_nextback1__back_button"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button
                    className={
                      !(dirty && isValid)
                        ? "container_for_nextback1__disabled_button"
                        : "container_for_nextback1__submit_button"
                    }
                    type="submit"
                    disabled={!(dirty && isValid)}
                  >
                    Next
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BankAccountPage;

import React, { useEffect, useState } from 'react';
import SelectInputBox from '../select_input/SelectInput';
import { getCategoryValues } from '../../api';

const CategorySelect = ({ className, id, name, onChange,selected }) => {
  const [data, setData] = useState([]);

  const CategoryValues = async () => {
    const res = await getCategoryValues();
    setData(res?.data?.data);
  };

  useEffect(() => {
    CategoryValues();
  }, []);

  return (
    <SelectInputBox
      onChange={onChange}
      data={data}
      className={className}
      id={id}
      name={name}
      title={'name'}
      value={'id'}
      selected={selected}
    ></SelectInputBox>
  );
};

export default CategorySelect;

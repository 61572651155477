import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import SelectCountry from '../SelectCountry/SelectCountry';
import { parse } from 'date-fns';
import InputField from '../input_field/Input';
import { Row, Col, Container } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

const min = '01/01/1800';
const max = '31/12/2018';

const AddPersonForm = ({ handleNext, handleBack, addPerson, vatNumber, origin }) => {
  const initialValues={
    temp_id:'',
    partner_name: '',
    partner_lastName: '',
    birth_date: '',
    vat_number: '',
    country_input: 'United Kingdom',
    city_name: '',
    address_name_line1: '',
    address_name_line2: '',
    postal_code: '',
    job_title:''
 }
  
  const [ownerInfo, setOwnerInfo] = useState(initialValues);
  
  const uk_postcode_regex =
    /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/;

  // const nameReg = /^[a-zA-Z]*$/;

  const validationSchema = Yup.object().shape({
    partner_name: Yup.string()
      .required('First name cannot be blank'),
    partner_lastName: Yup.string()
      .required('Last name cannot be blank'),
    birth_date: Yup.date()
      .transform((value, originalValue) =>
        parse(originalValue, 'dd/MM/yyyy', new Date())
      )
      .typeError('Please enter a valid date of birth')
      .min(min, `startDate should be equal or greater than ${min}`)
      .max(max, `end date should not be greater than this date ${max}`)
      .required('DOB cannot be blank'),
    city_name: Yup.string().required('City name cannot be blank'),
    address_name_line1: Yup.string().required('Address cannot be blank'),
    postal_code: Yup.string()
      .matches(uk_postcode_regex, 'Please enter a valid post code')
      .required('Postal code cannot be blank'),
  });
  
  const getStoredDetail = ()=>{
    localStorage.getItem('key_persons');
    let detail = localStorage.getItem('key_persons');
    if (detail != undefined && detail != null) {
      detail = JSON.parse(detail);
      if(origin == 'sole-trader'){
        let trader = detail.filter(e=> e.is_owner == true && e.is_executive == true);
        if(trader.length !== 0){
            setOwnerInfo({
                temp_id:trader[0].temp_id,
                partner_name: trader[0].first_name,
                partner_lastName: trader[0].last_name,
                birth_date: trader[0].date_of_birth,
                vat_number: trader[0].vat_number ?? '',
                country_input: 'United Kingdom',
                city_name: trader[0].address.city,
                address_name_line1: trader[0].address.address_1,
                address_name_line2: trader[0].address.address_2,
                postal_code: trader[0].address.postcode,
                job_title:''
            })
        }
      }else if(origin == 'company-ceo'){
        let ceo = detail.filter(e=> e.job_title == 'CEO' && e.is_executive == true);
        if(ceo.length == 0){
            return;
        }
        setOwnerInfo({
            temp_id:ceo[0].temp_id,
            partner_name: ceo[0].first_name,
            partner_lastName: ceo[0].last_name,
            birth_date: ceo[0].date_of_birth,
            vat_number: ceo[0].vat_number ?? '',
            country_input: 'United Kingdom',
            city_name: ceo[0].address.city,
            address_name_line1: ceo[0].address.address_1,
            address_name_line2: ceo[0].address.address_2,
            postal_code: ceo[0].address.postcode,
            job_title:'CEO'
        })
      }
    }
    
    if(origin == 'contracts'){
        let detail = localStorage.getItem('contracts_persons');
        if (detail != undefined && detail != null) {
            detail = JSON.parse(detail);
            setOwnerInfo({
                temp_id:detail.temp_id,                                                         
                partner_name: detail.first_name,
                partner_lastName: detail.last_name,
                birth_date: detail.date_of_birth,
                vat_number: detail.vat_number ?? '',
                country_input: 'United Kingdom',
                city_name: detail.address.city,
                address_name_line1: detail.address.address_1,
                address_name_line2: detail.address.address_2,
                postal_code: detail.address.postcode,
                job_title:''
            })
        }
    }
  }
  
  useEffect(() => {
    getStoredDetail();
  }, []);
  
  const onSubmit = (values) => {
    handleNext();
    const postAPI = {
      temp_id:values.temp_id,
      first_name: values.partner_name,
      last_name: values.partner_lastName,
      date_of_birth: values.birth_date,
      vat_number: values.vat_number,
      address: {
        city: values?.city_name,
        postcode: values?.postal_code,
        address_1: values?.address_name_line1,
        address_2: values?.address_name_line2,
        country: values?.country_input,
      },
      job_title:values.job_title
    };
    if(origin == 'sole-trader'){
        postAPI['is_owner'] = true;
        postAPI['is_executive'] = true;
    }else if(origin == 'company-ceo'){
        postAPI['is_executive'] = true;
        postAPI['job_title'] = 'CEO';
    }else if(origin == 'contracts'){
        postAPI['is_executive'] = true;
    }
    const vatNumber1 = {
      vat_number: values.vat_number,
      address: {
        city: values?.city_name,
        postcode: values?.postal_code,
        address_1: values?.address_name_line1,
        address_2: values?.address_name_line2,
        country: values?.country_input,
      },
    };
    const previousFormData = localStorage.getItem('key_persons');
    let returnData;
    if (previousFormData) {
      let persons = JSON.parse(previousFormData);
      if(postAPI.temp_id == ''){
        postAPI['temp_id'] = uuid();
        returnData = [...persons, postAPI]; 
      }else{
        const index = persons.findIndex(e=> e.temp_id == postAPI.temp_id);
        persons[index] = postAPI;
        returnData = persons; 
      }
    } else {
      postAPI['temp_id'] = uuid();
      returnData = [postAPI];
    }
    if(origin != 'contracts'){
        localStorage.setItem('key_persons', JSON.stringify(returnData));
    }else if(origin == 'contracts'){
        localStorage.setItem('contracts_persons', JSON.stringify(postAPI));
    }
    if(origin == 'company-ceo'){
        localStorage.setItem('clone_seller_members', JSON.stringify(postAPI));        
    }
    if (vatNumber === true) {
      localStorage.setItem('company_info', JSON.stringify(vatNumber1));
    }
  };

  const handleDateOfBirth = (e, setFieldValue) => {
    const { name, value } = e.target;
    if (value.length <= 10) {
      let dummy = value
        .replace(/^(\d\d)(\d)$/g, '$1/$2')
        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
        .replace(/[^\d\/]/g, '');
      setFieldValue('birth_date', dummy);
    }
  };

  return (
    <div className='form_container_company_own'>
      <Formik
        enableReinitialize={true}
        initialValues={ownerInfo}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          dirty,
          isValid,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className='buisness-signup-form3_2'>
              <label
                className='buisness-signup-form3_2__label'
                htmlFor='partnerShip_input_buisnessSignup1'
              >
                Full name
              </label>
              <div className='buisness-signup-form3_2__container-input-box'>
                <Container className='buisness-signup-form3_2__container-input-box__container'>
                  <Row className='buisness-signup-form3_2__container-input-box__container__row'>
                    <Col className='buisness-signup-form3_2__container-input-box__container__row__col1'>
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='First name'
                        value={values.partner_name}
                        className={
                          errors.partner_name && touched.partner_name
                            ? 'buisness-signup-form3_2__container-input-box__error_fullname'
                            : 'buisness-signup-form3_2__container-input-box__fullname_input'
                        }
                        id='partnerShip_input_buisnessSignup1'
                        name='partner_name'
                      ></input>
                      {errors.partner_name && touched.partner_name ? (
                        <div className='buisness-signup-form3_2__error-color'>
                          {errors.partner_name}
                        </div>
                      ) : null}
                    </Col>
                    <Col className='buisness-signup-form3_2__container-input-box__container__row__col2'>
                      <input
                        onChange={handleChange}
                        value={values.partner_lastName}
                        onBlur={handleBlur}
                        placeholder='Last name'
                        className={
                          errors.partner_lastName && touched.partner_lastName
                            ? 'buisness-signup-form3_2__container-input-box__error_lastname'
                            : 'buisness-signup-form3_2__container-input-box__fullname_input_lastname'
                        }
                        id='partnerShip_input_buisnessSignup1'
                        name='partner_lastName'
                      ></input>
                      {errors.partner_lastName && touched.partner_lastName ? (
                        <div className='buisness-signup-form3_2__error-color'>
                          {errors.partner_lastName}
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>

            <div className='buisness-signup-form3_2'>
              <label
                className='buisness-signup-form3_2__label'
                htmlFor='Register_input_buisnessSignup1'
              >
                Date of Birth
              </label>
              <div className='buisness-signup-form3_2__container-input-box'>
                <input
                  name='birth_date'
                  onChange={(e) => {
                    handleDateOfBirth(e, setFieldValue);
                  }}
                  value={values.birth_date}
                  onBlur={handleBlur}
                  type='text'
                  className={
                    errors.birth_date && touched.birth_date
                      ? 'buisness-signup-form3_2__container-input-box__error'
                      : 'buisness-signup-form3_2__container-input-box__input-box'
                  }
                  placeholder='  / /  '
                  id='Register_input_buisnessSignup1'
                ></input>

                {errors.birth_date && touched.birth_date ? (
                  <div className='buisness-signup-form3_2__error-color'>
                    {errors.birth_date}
                  </div>
                ) : null}
              </div>
            </div>
            {vatNumber === true ? (
              <div className='buisness-signup-form2'>
                <label
                  className='buisness-signup-form2__label'
                  htmlFor='store_input_buisnessSignup1'
                  style={{ paddingTop: '20px' }}
                >
                  VAT Number{' '}
                  <p className='optional_style_form1_2'>(Optional)</p>
                </label>
                <div className='buisness-signup-form2__container-input-box'>
                  <InputField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='GB123456789'
                    className={
                      errors.vat_number && touched.vat_number
                        ? 'buisness-signup-form2__container-input-box__error'
                        : 'buisness-signup-form2__container-input-box__input-box'
                    }
                    id='store_input_buisnessSignup1'
                    name='vat_number'
                    value={values.vat_number}
                  ></InputField>
                  {errors.vat_number && touched.vat_number ? (
                    <div className='input_field_error_color'>
                      {errors.vat_number}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            <div className='buisness-signup-form2_address'>
              <label
                className='buisness-signup-form2_address__label'
                htmlFor='url_input_buisnessSignup1'
              >
                Registered Address
              </label>
              <div
                className='input-field-container__container-input-box_country'
                style={{ marginLeft: '6px', width: '60%' }}
              >
                <SelectCountry
                  value={values.country_input}
                  onChange={handleChange}
                  name='country_input'
                  className='input-field-container__container-input-box_country__input-box'
                  id='url_input_buisnessSignup1'
                ></SelectCountry>
              </div>
            </div>

            <div className='buisness-signup-form2_address'>
              <label
                className='buisness-signup-form2_address__label'
                htmlFor='url_input_buisnessSignup1'
              ></label>
              <div className='buisness-signup-form2_address__container-input-box'>
                <input
                  className={
                    errors.address_name_line1 && touched.address_name_line1
                      ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                      : 'buisness-signup-form2_address__container-input-box__postal_container'
                  }
                  type='text'
                  placeholder='Address Line 1'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='address_name_line1'
                  value={values.address_name_line1}
                ></input>
                {errors.address_name_line1 && touched.address_name_line1 ? (
                  <div className='buisness-signup-form2_address__error-color'>
                    {errors.address_name_line1}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='buisness-signup-form2_address'>
              <label
                className='buisness-signup-form2_address__label'
                htmlFor='url_input_buisnessSignup1'
              ></label>
              <div className='buisness-signup-form2_address__container-input-box'>
                <input
                  className={
                    errors.address_name_line2 && touched.address_name_line2
                      ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                      : 'buisness-signup-form2_address__container-input-box__postal_container'
                  }
                  type='text'
                  placeholder='Address Line 2'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='address_name_line2'
                  value={values.address_name_line2}
                ></input>
                {errors.address_name_line2 && touched.address_name_line2 ? (
                  <div className='buisness-signup-form2_address__error-color'>
                    {errors.address_name_line2}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='buisness-signup-form2_address'>
              <label
                className='buisness-signup-form2_address__label'
                htmlFor='url_input_buisnessSignup1'
              ></label>
              <div className='buisness-signup-form2_address__container-input-box'>
                <input
                  className={
                    errors.postal_code && touched.postal_code
                      ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                      : 'buisness-signup-form2_address__container-input-box__postal_container'
                  }
                  type='text'
                  placeholder='Post Code'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='postal_code'
                  value={values.postal_code}
                ></input>
                {errors.postal_code && touched.postal_code ? (
                  <div className='buisness-signup-form2_address__error-color'>
                    {errors.postal_code}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='buisness-signup-form2_address'>
              <label
                className='buisness-signup-form2_address__label'
                htmlFor='url_input_buisnessSignup1'
              ></label>
              <div className='buisness-signup-form2_address__container-input-box'>
                <input
                  className={
                    errors.city_name && touched.city_name
                      ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                      : 'buisness-signup-form2_address__container-input-box__postal_container'
                  }
                  type='text'
                  placeholder='City'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='city_name'
                  value={values.city_name}
                ></input>
                {errors.city_name && touched.city_name ? (
                  <div className='buisness-signup-form2_address__error-color'>
                    {errors.city_name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className='container_for_nextback'>
              {addPerson ? (
                <button className='add_person_button_radio_button'>
                  + Add Person
                </button>
              ) : (
                ''
              )}
              <button
                className='container_for_nextback__back_button'
                onClick={handleBack}
              >
                Back
              </button>
              <button
                className={
                  !(isValid)
                    ? 'container_for_nextback__disabled_button'
                    : 'container_for_nextback__submit_button'
                }
                type='submit'
                disabled={!(isValid)}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddPersonForm;

// Default Imports
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";

import "./onboarding-assets/css/main.css";
// import "./onboarding-assets/fonts/font.css";
import './assets/fonts/font.css';
// import './assets/fonts/font.css';


// Redux Imports
import { Store } from "./state";

// Components Imports
import NavigationRoutes from "./routes";

// Styles Imports
import "./styles/styles.scss";

import "bootstrap/dist/css/bootstrap.css";
import { ToastContainer } from "react-toastify";
import { BasicContextProvider } from "./contextApi/BasicContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BasicContextProvider>
    <BrowserRouter>
      <Provider store={Store}>
        <NavigationRoutes />
        <ToastContainer
          position="bottom-center"
          autoClose={2500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          closeButton={false}
          // pauseOnFocusLoss
          draggable
          // pauseOnHover
          theme="dark"
        />
      </Provider>
    </BrowserRouter>
  </BasicContextProvider>
);

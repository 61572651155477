import { makeStyles } from '@material-ui/core';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      phone: 320,
      tablet: 768,
      'small-desktop': 1024,
      'mid-desktop': 1280,
      LGdesktop: 1920,
    },
  },
});

const useStyles = makeStyles({
  root: {},
  buisnessSignup_grid_container: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.between('phone', 'small-desktop')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  buisnessSignup_item_container: {
    width: '50%',
    height: '100%',
    boxShadow: '0px !important',
    marginTop: '50px',
    [theme.breakpoints.between('phone', 'small-desktop')]: {
      width: '100%',
      marginTop: '0px',
    },
  },
  steps_container_active: {
    width: '135px',
    borderBottom: '4px solid black',
    paddingLeft: '0px !important',
    marginLeft: '5px',
    marginRight: '24px',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    borderRadius: '4px',
    [theme.breakpoints.between('phone', 'small-desktop')]: {
      fontSize: '12px',
    },
  },
  steps_container_disable: {
    width: '135px',
    borderBottom: '4px solid #A8BAC9',
    color: 'grey',
    paddingLeft: '0px !important',
    marginLeft: '5px',
    marginRight: '24px',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    borderRadius: '4px',
    [theme.breakpoints.between('phone', 'small-desktop')]: {
      fontSize: '12px',
    },
  },
  stepper_container: {
    marginLeft: '6%',
    marginTop: '40px',
    padding: '0px',
    [theme.breakpoints.between('phone', 'small-desktop')]: {
      marginTop: '50px',
    },
  },
  steps_container: {
    width: '140px',
    borderBottom: '4px solid black',
    paddingLeft: '0px !important',
    marginTop: '20px',
    marginLeft: '5px',
    marginRight: '5px',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
  },

  stepIconRoot: {
    display: 'none',
  },
  img_buisness_signup_page: {
    width: '50%',
    height: '100%',
    position: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    [theme.breakpoints.between('phone', 'small-desktop')]: {
      width: '100%',
      position: 'static',
    },
  },
});

export { useStyles };

import React from 'react';

const InputField = ({ placeholder, className, onChange, name, id, onBlur, value }) => {
  return (
    <input
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      id={id}
      value={value}
      onBlur={onBlur}
    ></input>
  );
};

export default InputField;

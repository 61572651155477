import React from 'react';
import { BsCart3 } from 'react-icons/bs';
import { FiActivity, FiSettings, FiUser } from 'react-icons/fi';
import { TbReportAnalytics } from 'react-icons/tb';
import { AiOutlinePoundCircle, AiOutlineStar } from 'react-icons/ai';
import { BsArrowUpRight } from 'react-icons/bs';
import { HomeLogo } from '../../assets/icons/sidebar';
import { HomeActiveLogo } from '../../assets/icons/sidebar';
import { CustomerLogo } from '../../assets/icons/sidebar';
import { CustomerActiveLogo } from '../../assets/icons/sidebar';
import { ReportLogo } from '../../assets/icons/sidebar';
import { ReportActiveLogo } from '../../assets/icons/sidebar';
import { PayoutsLogo } from '../../assets/icons/sidebar';
import { PayoutsActiveLogo } from '../../assets/icons/sidebar';
import { OrdersLogo } from '../../assets/icons/sidebar';
import { OrdersActiveLogo } from '../../assets/icons/sidebar';
import { SettingsLogo } from '../../assets/icons/sidebar';
import { SettingsActiveLogo } from '../../assets/icons/sidebar';




const SideBarNav = [
  {
    id: 1,
    link: '/home',
    section: 'home',
    icons: <FiActivity />,
    icon:  <HomeLogo.default />,
    activeIcon:  <HomeActiveLogo.default />,
    text: 'Home',
    uuid: 11,
  },
  {
    id: 2,
    link: '/orders',
    section: 'orders',
    icons: <BsCart3 />,
    icon:  <OrdersLogo.default />,
    activeIcon:  <OrdersActiveLogo.default />,
    text: 'Orders',
    uuid: 12,
  },
  {
    id: 3,
    link: '/payouts',
    section: 'payouts',
    icons: <AiOutlinePoundCircle />,
    icon: <PayoutsLogo.default />,
    activeIcon: <PayoutsActiveLogo.default />,
    text: 'Payouts & Balances',
    uuid: 13,
  },
  {
    id: 4,
    link: '/reports',
    section: 'reports',
    icons: <TbReportAnalytics />,
    icon: <ReportLogo.default />,
    activeIcon: <ReportActiveLogo.default />, 
    text: 'Reports',
    uuid: 14,
    childList: [4.1, 4.2, 4.3],
    children: [
      {
        id: 4.1,
        link: '/transaction-report',
        section: 'transaction-report',
        text: 'Transaction Report',
        uuid: 15,
      },
      {
        id: 4.2,
        link: '/store-credit-report',
        section: 'store-credit-report',
        text: 'Store Credits',
        uuid: 16,
      },
      //{
      //  id: 4.3,
      //  link: '/payout-report',
      //  section: 'payout-report',
      //  text: 'Payout Report',
      //  uuid: 17,
      //},
    ],
  },
  {
    id: 5,
    link: '/customers',
    section: 'customers',
    icons: <FiUser />,
    icon: <CustomerLogo.default />,
    activeIcon: <CustomerActiveLogo.default />,
    text: 'Customers',
    uuid: 18,
  },
  {
    id: 6,
    link: '/settings',
    section: 'settings',
    icons: <FiSettings />,
    icon:  <SettingsLogo.default />,
    activeIcon:  <SettingsActiveLogo.default />,
    text: 'Settings',
    uuid: 19,
  },
  //   {
  //     id: 7,
  //     link: '/installation',
  //     section: 'installation',
  //     icons: <AiOutlineStar />,
  //     icon: star,
  //     activeIcon: starActive,
  //     text: 'On-Site Messaging',
  //     uuid: 20,
  //     childList:[7, 7.1],
  //     children: [
  //       {
  //         id: 7,
  //         link: '/installation',
  //         section: 'installation',
  //         text: 'Installation',
  //         uuid: 21,
  //       },
  //       {
  //         id: 7.1,
  //         link: '/placements',
  //         section: 'placements',
  //         text: 'Placements',
  //         uuid: 22,
  //       },
  //     ],
  //   },
  {
    id: 8,
    link: '/developer',
    section: 'developer',
    text: 'Developer hub',
    dev: true,
    uuid: 23,
  },
];

export default SideBarNav;

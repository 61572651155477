import React from 'react';
import AddPersonForm from '../CompanyOwn/AddPersonForm';
import { useNavigate } from 'react-router-dom';

const BuisnessSignupform3_2 = ({ handleNext }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/onBoarding');
  };
  return (
    <div className='buisness-SignupForm3_2-Container'>
      <div className='buisness-SignupForm3_2-Container__content'>
        <h4 className='buisness-SignupForm3_2-Container__content__title'>
          Are there any people who <br /> own more than 25% of
          <br /> the company?
        </h4>
        <p className='buisness-SignupForm3_2-Container__content__subtitle'>
          Please provide information of the people that own more than 25% of{' '}
          <br /> shares or voting rights of the company or excercise similar
          control.
        </p>
      </div>
      <div></div>
      <AddPersonForm
        origin="sole-trader"
        handleBack={handleBack}
        handleNext={handleNext}
        addPerson={false}
        vatNumber={true}
      ></AddPersonForm>
    </div>
  );
};

export default BuisnessSignupform3_2;

import React from 'react';
import './LetsTalk.scss';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import InputField from '../input_field/Input';
import { Images } from '../../assets';
import { Link } from 'react-router-dom';

const LetsTalk = () => {
  const phoneRegExp =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  const nameReg = /^[a-zA-Z]*$/;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .matches(nameReg, 'Letters only please (A-Z)')
      .required(''),
    lastname: Yup.string()
      .matches(nameReg, 'Letters only please (A-Z)')
      .required(''),
    phone_number: Yup.string()
      .matches(phoneRegExp, 'Please add a valid phone number')
      .required(''),
  });

  const onSubmit = () => {
    if (validationSchema) {
      navigate('/home/selectEcommerce/bookDemo/confirm');
    }
  };
  return (
    <div className='LetsTalk_container'>
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          phone_number: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          dirty,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className='input-field-container1'>
              <label
                className='input-field-container1__label'
                htmlFor='fullname_input'
              >
                First name
              </label>
              <div className='input-field-container1__container-input-box'>
                <InputField
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className={
                    errors.firstname && touched.firstname
                      ? 'input-field-container1__container-input-box__error'
                      : 'input-field-container1__container-input-box__input-box'
                  }
                  id='fullname_input'
                  name='firstname'
                  placeholder='John'
                ></InputField>
                {errors.firstname && touched.firstname ? (
                  <div className='input-field-container1__error-color'>
                    {errors.firstname}
                  </div>
                ) : null}
              </div>
            </div>

            <div className='input-field-container1'>
              <label
                className='input-field-container1__label'
                htmlFor='lastname_input'
              >
                Last name
              </label>
              <div className='input-field-container1__container-input-box'>
                <InputField
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className={
                    errors.lastname && touched.lastname
                      ? 'input-field-container1__container-input-box__error'
                      : 'input-field-container1__container-input-box__input-box'
                  }
                  id='lastname_input'
                  name='lastname'
                  placeholder='Doe'
                ></InputField>
                {errors.lastname && touched.lastname ? (
                  <div className='input-field-container1__error-color'>
                    {errors.lastname}
                  </div>
                ) : null}
              </div>
            </div>

            <div className='input-field-container1'>
              <label
                className='input-field-container1__label'
                htmlFor='ecommerce_url_firstpage'
              >
                Phone number
              </label>
              <div className='input-field-container1__container-input-box'>
                <InputField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.phone_number && touched.phone_number
                      ? 'input-field-container1__container-input-box__error'
                      : 'input-field-container1__container-input-box__input-box'
                  }
                  id='ecommerce_url_firstpage'
                  name='phone_number'
                  placeholder='07512345678'
                ></InputField>
                {errors.phone_number && touched.phone_number ? (
                  <div className='input-field-container1__error-color'>
                    {errors.phone_number}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='submit_container1'>
              <Link to='/home/selectEcommerce'>
                <button className='submit_container1__back_button'>Back</button>
              </Link>

              <button
                className={
                  !(dirty && isValid)
                    ? 'submit_container1__disabled_button'
                    : 'submit_container1__button'
                }
                disabled={!(dirty && isValid)}
                type='submit'
              >
                {' '}
                Submit
                <span className='submit_container1__user_access_setup_arrowicon'>
                  <Images.smallArrowRight />
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LetsTalk;

/**
 * Global file for all routes
 */

import React, { lazy, Suspense } from 'react';
import Home from '../pages/DemoBookingPages/demo_home';
import ProtectedRoute from './privateRoute';
// /login page /
import SecondPage from '../pages/DemoBookingPages/demo_second_page';
import ThirdpageDemoBooking from '../pages/DemoBookingPages/demo_third_page';
import FourthConfirmPage from '../pages/DemoBookingPages/demo_fouth_confirm_page';
import BuisnessSignup1 from '../pages/BuisnessSignup/firstpage_signup';
import BuisnessSignup3 from '../pages/BuisnessSignup3/signupform3';
import BuisnessSignupform2 from '../pages/BuisnessSignup2/BuisnessSignupform2';
// dashboard
import MainLayout from '../Layout/MainLayout';
// import Overview from '../pages/Home/Home';
const Overview = lazy(() => import('../pages/Home/Home'));
// import Orders from '../pages/Orders/Orders';
const Orders = lazy(() => import('../pages/Orders/Orders'));
// import Settings from '../pages/Settings/Settings';
const Settings = lazy(() => import('../pages/Settings/Settings'));
// import Customers from '../pages/Customers/Customers';
const Customers = lazy(() => import('../pages/Customers/Customers'));
// import PayoutBalance from '../pages/PayoutBalance/PayoutBalance';
const PayoutBalance = lazy(() =>
  import('../pages/PayoutBalance/PayoutBalance')
);
// import Reports from '../pages/Reports/Reports';
const Reports = lazy(() => import('../pages/Reports/Reports'));
// import CustomerOrders from '../pages/Orders/CustomerOrders/CustomerOrders';
const CustomerOrders = lazy(() =>
  import('../pages/Orders/CustomerOrders/CustomerOrders')
);
// import PayoutDetails from '../pages/PayoutBalance/PayoutDetails/PayoutDetails';
const PayoutDetails = lazy(() =>
  import('../pages/PayoutBalance/PayoutDetails/PayoutDetails')
);
// import CustomersTrack from '../pages/Customers/CustomersTrack/CustomersTrack';
const CustomersTrack = lazy(() =>
  import('../pages/Customers/CustomersTrack/CustomersTrack')
);
// import MerchantTrans from '../pages/Reports/MerchantTrans';
const MerchantTrans = lazy(() => import('../pages/Reports/MerchantTrans'));
// import StoreReport from '../pages/Reports/StoreReport';
const StoreReport = lazy(() => import('../pages/Reports/StoreReport'));
// import Accounts from '../pages/Settings/Accounts';
const Accounts = lazy(() => import('../pages/Settings/Accounts'));
// import ApiKeys from '../pages/Settings/ApiKeys';
const ApiKeys = lazy(() => import('../pages/Settings/ApiKeys'));
// import UserPassword from '../pages/Settings/UserPassword';
const UserPassword = lazy(() => import('../pages/Settings/UserPassword'));
import UserSignup from '../pages/UserSignup/UserSignup';
import SetupLoading from '../pages/LoadingPage/SetupLoading';
// import ConfirmOtp from '../components/ConfirmOtp/ConfirmOtp';
import ConfirmOtp from '../components/ConfirmOtp/ConfirmOtp';
// import PublicInfo from '../pages/Settings/PublicInfo';
const PublicInfo = lazy(() => import('../pages/Settings/PublicInfo'));
// import Branding from '../pages/Settings/Branding';
const Branding = lazy(() => import('../pages/Settings/Branding'));
// import WebHooks from '../pages/Settings/WebHooks';
const WebHooks = lazy(() => import('../pages/Settings/WebHooks'));
// import BankAccount from '../pages/Settings/BankAccount';
const BankAccount = lazy(() => import('../pages/Settings/BankAccount'));
// import Management from '../pages/Settings/Management';
const Management = lazy(() => import('../pages/Settings/Management'));
// import BussinessInfo from '../pages/Settings/BussinessInfo';
const BussinessInfo = lazy(() => import('../pages/Settings/BussinessInfo'));
// import Installation from '../pages/OnsiteMessaging/Installation';
const Installation = lazy(() =>
  import('../pages/OnsiteMessaging/Installation')
);
// import Placements from '../pages/OnsiteMessaging/Placements';
const Placements = lazy(() => import('../pages/OnsiteMessaging/Placements'));
// import MerchantLogin from '../pages/Merchant Login/MerchantLogin';
import MerchantLogin from '../pages/Merchant Login/MerchantLogin';
// import BeneficialOwner from '../pages/Settings/BeneficialOwner';
const BeneficialOwner = lazy(() => import('../pages/Settings/BeneficialOwner'));
// import Directors from '../pages/Settings/Directors';
const Directors = lazy(() => import('../pages/Settings/Directors'));
// import Dummy from '../../dummy/Dummy';
const Dummy = lazy(() => import('../../dummy/Dummy'));
import FirstOnboardingForm from '../components/FirstOnboardingForm/FirstOnboardingForm';
import Spinner from '../components/Loader/Spinner';
const OnboardingPage = lazy(() => import('../components/Onboarding/OnboardingPage'));

export const appRoutes = [
  {
    // routes with Layout
    element: <MainLayout />,
    children: [
      {
        // protected routes
        element: <ProtectedRoute />,
        children: [
          {
            path: '/home',
            element: (
              <Suspense fallback={<Spinner />}>
                <Overview />
              </Suspense>
            ),
          },
          {
            path: `/orders`,
            element: (
              <Suspense fallback={<Spinner />}>
                <Orders />
              </Suspense>
            ),
          },
          {
            path: '/orders/:id',
            element: (
              <Suspense fallback={<Spinner />}>
                <CustomerOrders />
              </Suspense>
            ),
          },
          {
            path: '/payouts',
            element: (
              <Suspense fallback={<Spinner />}>
                <PayoutBalance />
              </Suspense>
            ),
          },
          {
            path: '/payouts/:id',
            element: (
              <Suspense fallback={<Spinner />}>
                <PayoutDetails />
              </Suspense>
            ),
          },
          {
            path: '/reports',
            element: (
              <Suspense fallback={<Spinner />}>
                <Reports />
              </Suspense>
            ),
          },
          {
            path: '/transaction-report',
            element: (
              <Suspense fallback={<Spinner />}>
                <MerchantTrans type='merchant' />
              </Suspense>
            ),
          },
          {
            path: '/payout-report',
            element: (
              <Suspense fallback={<Spinner />}>
                <MerchantTrans type='balance' />
              </Suspense>
            ),
          },
          {
            path: '/store-credit-report',
            element: (
              <Suspense fallback={<Spinner />}>
                <StoreReport />
              </Suspense>
            ),
          },
          {
            path: '/customers',
            element: (
              <Suspense fallback={<Spinner />}>
                <Customers />
              </Suspense>
            ),
          },
          {
            path: '/customers/:id',
            element: (
              <Suspense fallback={<Spinner />}>
                <CustomersTrack />
              </Suspense>
            ),
          },
          {
            path: '/settings',
            element: (
              <Suspense fallback={<Spinner />}>
                <Settings />
              </Suspense>
            ),
          },
          {
            path: '/settings/account',
            element: (
              <Suspense fallback={<Spinner />}>
                <Accounts />
              </Suspense>
            ),
          },
          {
            path: '/settings/user',
            element: (
              <Suspense fallback={<Spinner />}>
                <UserPassword />
              </Suspense>
            ),
          },
          {
            path: '/settings/public',
            element: (
              <Suspense fallback={<Spinner />}>
                <PublicInfo />
              </Suspense>
            ),
          },
          {
            path: '/settings/branding',
            element: (
              <Suspense fallback={<Spinner />}>
                <Branding />
              </Suspense>
            ),
          },
          {
            path: '/settings/business',
            element: (
              <Suspense fallback={<Spinner />}>
                <BussinessInfo />
              </Suspense>
            ),
          },
          {
            path: '/settings/management',
            element: (
              <Suspense fallback={<Spinner />}>
                <Management />
              </Suspense>
            ),
          },
          {
            path: '/settings/beneficial',
            element: (
              <Suspense fallback={<Spinner />}>
                <BeneficialOwner />
              </Suspense>
            ),
          },
          // {
          //   path: '/settings/company-director',
          //   element: <Directors name='company-director' />,
          // },
          {
            path: '/settings/business-excecutive',
            element: (
              <Suspense fallback={<Spinner />}>
                <Directors name='business-excecutive' />
              </Suspense>
            ),
          },
          {
            path: '/settings/payout-account',
            element: (
              <Suspense fallback={<Spinner />}>
                <BankAccount />
              </Suspense>
            ),
          },
        //   {
        //     path: '/settings/apikey',
        //     element: (
        //       <Suspense fallback={<Spinner />}>
        //         <ApiKeys />
        //       </Suspense>
        //     ),
        //   },
        //   {
        //     path: '/settings/webhooks',
        //     element: (
        //       <Suspense fallback={<Spinner />}>
        //         <WebHooks />
        //       </Suspense>
        //     ),
        //   },

        //   {
        //     path: '/installation',
        //     element: (
        //       <Suspense fallback={<Spinner />}>
        //         <Installation />
        //       </Suspense>
        //     ),
        //   },
        //   {
        //     path: '/placements',
        //     element: (
        //       <Suspense fallback={<Spinner />}>
        //         <Placements />
        //       </Suspense>
        //     ),
        //   },
          {
            path: '/developer',
            element: (
              <Suspense fallback={<Spinner />}>
                <Placements />
              </Suspense>
            ),
          },
          {
            path: '/dummy',
            element: (
              <Suspense fallback={<Spinner />}>
                <Dummy />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    // element: <ProtectedRoute />,
    children: [
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/home/selectEcommerce',
        element: <SecondPage />,
      },
      {
        path: '/home/selectEcommerce/bookDemo',
        element: <ThirdpageDemoBooking />,
      },
      {
        path: '/home/selectEcommerce/bookDemo/confirm',
        element: <FourthConfirmPage />,
      },
      {
        path: '/signup/:id',
        element: <UserSignup />,
      },
      {
        path: '/onBoarding',
        element: <FirstOnboardingForm />,
      },
      {
        path: '/company',
        element: <BuisnessSignup1 />,
      },
      {
        path: '/partnership',
        element: <BuisnessSignupform2 />,
      },
      {
        path: '/sole-trader',
        element: <BuisnessSignup3 />,
      },
      {
        path: '/loading',
        element: <SetupLoading />,
      },
      {
        path: '',
        element: <MerchantLogin />,
      },
      {
        path: '/signup/confirm',
        element: <ConfirmOtp />,
      },
      {
        path: '/onboarding/:id',
        element: (
            <Suspense fallback={<Spinner />}>
              <OnboardingPage />
            </Suspense>
        ),
      },      
    ],
  },
];

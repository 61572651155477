import React from 'react';
import AddPersonForm from '../CompanyOwn/AddPersonForm';

const BuisnessSignupform2_2 = ({ handleNext, handleBack }) => {
  return (
    <div className='buisness-SignupForm2_2-Container'>
      <div className='buisness-SignupForm2_2-Container__content'>
        <h4 className='buisness-SignupForm2_2-Container__content__title'>
          Who manages the <br></br> buisness?
        </h4>
        <p className='buisness-SignupForm2_2-Container__content__subtitle'>
          We are legally required to know all the people that excercise control
          over <br></br> your buisness's management.{' '}
        </p>
      </div>
      <div>
        <AddPersonForm origin="company-ceo" handleNext={handleNext} handleBack={handleBack} />
      </div>
    </div>
  );
};

export default BuisnessSignupform2_2;

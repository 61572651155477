import * as React from 'react';
import BuisnessSignupform3_2 from '../../components/buisnessSignup3rdform/Signupform3_2';
import BankAccountPage from '../../components/BankAccountPage/BankAccountPage';

export default function getStepContentform3(
  activeStep,
  handleNext,
  handleBack,
  setData1,
  data1
) {
  switch (activeStep) {
    case 0:
      return (
        <>
          <BuisnessSignupform3_2
            handleNext={handleNext}
            handleBack={handleBack}
            data1={data1}
            setData1={setData1}
          />
        </>
      );
    case 1:
      return (
        <>
          <BankAccountPage
            handleNext={handleNext}
            handleBack={handleBack}
            data1={data1}
            setData1={setData1}
          />
        </>
      );
  }
}

import axios from "axios";
import { ACCESS_TOKEN, TEST_MODE } from "./constants";

const sandBoxMode = localStorage.getItem(TEST_MODE);

export let prodUrl = "";
export let sandboxUrl = "";

let baseURL = "";

if (process.env.REACT_APP_ENV == "staging") {
  baseURL = "https://api-staging-dev.joinfam.com";
  prodUrl = "https://api-staging-dev.joinfam.com"; //api-staging-live.joinfam.com
  sandboxUrl = "https://api-staging-sandbox.joinfam.com";  
} else if (process.env.REACT_APP_ENV == "production") {
  baseURL = "https://api.joinfam.com";
  prodUrl = "https://api.joinfam.com";
  sandboxUrl = "https://api-sandbox.joinfam.com";
}

const createClient = () => {
  const client = axios.create(
    {
      // baseURL: 'https://api.hubapi.com',
      // baseURL: baseUrl,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer pat-eu1-b26430db-5dfc-4399-8ece-91a8ab77c33a",
        crossorigin: true,
        "Access-Control-Allow-Origin": "*",
        post: {
          "Access-Control-Allow-Origin": true,
        },
      },
    },
    { "Access-Control-Allow-Origin": "*" }
  );

  return client;
};
const http = createClient();

export default http;

const createClientWithoutHeaders = () => {
  const clientIwthoutHeader = axios.create({
    baseURL,
    withCredentials: true,
  });

  return clientIwthoutHeader;
};
export const httpWithoutHeaders = createClientWithoutHeaders();

// export default axios.create({
//   baseURL,
//   withCredentials: true,
//   headers: { token: 'Bearer token' },
// });

export const client = axios.create({
  baseURL,
});

client.interceptors.request.use(
  (request) => {
    const access_token = localStorage.getItem(ACCESS_TOKEN);
    request.headers["Authorization"] = `JWT ${access_token}`;
    return request;
  },
  (err) => Promise.reject(err)
);


export const nonSandboxClient = axios.create({
    prodUrl,
  });
  
  nonSandboxClient.interceptors.request.use(
    (request) => {
      const access_token = localStorage.getItem(ACCESS_TOKEN);
      request.headers["Authorization"] = `JWT ${access_token}`;
      return request;
    },
    (err) => Promise.reject(err)
  );
  
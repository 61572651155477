// function get the query params as object of key and value type without using library

// import React, { useContext } from 'react';
// import { client } from '../api/api';
import {
  ACCESS_TOKEN,
  MERCHANT_ID,
  MERCHANT_USER_ID,
  FIRST_NAME,
  LAST_NAME,
  SHOP_NAME,
  MERCHANT_APIKEY,
  MERCHANT_DETAILS
} from '../api/constants';
// import { BasicContext } from '../contextApi/BasicContext';

// const contextData = useContext(BasicContext);

export const useQuery = (params) => {
  const data = {};
  params
    .substring(1)
    .split('&')
    .map((val) => {
      if (val) {
        const indi = val.split('=');
        data[indi[0]] = indi[1];
      }
    });
  return data;
};

export const loadGMaps = (callback) => {
  const sellerId = localStorage.getItem('seller_id');
  const existingScript = document.getElementById('bankpay.js');
  if (!existingScript) {
    const script = document.createElement('script');
    script.setAttribute('defer', '');
    script.src = 'https://cdnjs.bankpay.to/js/bankpay_checkout.js';
    script.id = 'bankpay.js';
    // script.setAttribute('data-merchant-id', sellerId.toString());
    script.setAttribute('data-merchant-id', 'sel_5oj6z2ztmlnwp');
    script.setAttribute('mode', 'sandbox');
    script.setAttribute('charset', 'utf-8');
    document.head.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export const localStorageItems = (response) => {
  localStorage.setItem(ACCESS_TOKEN, response.data.data.access_token);
  localStorage.setItem(
    MERCHANT_ID,
    response.data.data.user.merchant.merchant_id
  );
  localStorage.setItem(
    MERCHANT_USER_ID,
    response.data.data.user.merchant_user_id
  );
  localStorage.setItem(FIRST_NAME, response.data.data.user.first_name);
  localStorage.setItem(LAST_NAME, response.data.data.user.last_name);
  localStorage.setItem(SHOP_NAME, response.data.data.user.merchant.name);
};

export const setMerchatApiKey = (key) => {
    localStorage.setItem(MERCHANT_APIKEY, key);
};

export const setMerchantDetails = (value) => {
  localStorage.setItem(MERCHANT_DETAILS, value);
};
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputField from '../../components/input_field/Input';
import CategorySelect from '../buisnessSIgnupSelectFields/CategorySelect';
import EntityType from '../buisnessSIgnupSelectFields/EntityType';
import { useNavigate } from 'react-router-dom';

const BuisnessSignupform1 = () => {
  const navigate = useNavigate();  
  const initialValues = {
    store_name: '',
    url_name: '',
    email_input: '',
    category_input: '1',
    entity_type_input: 'REGISTERED_COMPANY',
  }
  const [bussinessInfo, setBussinessInfo] = useState(initialValues);

  const getStoredDetail = async () => {
    let detail = localStorage.getItem('store_details');
    if(detail && detail != null){
        detail = JSON.parse(detail) 
        setBussinessInfo({
            store_name: detail.store_name,
            url_name: detail.store_url,
            email_input: detail.support_email,
            entity_type_input:detail.entity_type,
            category_input:detail.industry
        })
    }
  };

  useEffect(() => {
    getStoredDetail();
  }, []);
  
  const re =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  const validationSchema = Yup.object().shape({
    store_name: Yup.string().required('Store name cannot be blank'),
    url_name: Yup.string().matches(re, 'Please enter a valid URL').required('Store url cannot be blank'),
    email_input: Yup.string().email('Please enter a valid email').required('Email cannot be blank'),
  });

  const onSubmit = (values) => {
    localStorage.removeItem('clone_seller_members');
    localStorage.removeItem('key_persons');
    localStorage.removeItem('stakeholder');
    localStorage.removeItem('company_info');
    
    if (values.entity_type_input === 'PARTNERSHIP') {
      navigate('/partnership');
    } else if (values.entity_type_input === 'REGISTERED_COMPANY') {
      navigate('/company');
    } else if (values.entity_type_input === 'SOLE_TRADER') {
      navigate('/sole-trader');
    }

    const postAPI = {
      store_name: values.store_name,
      store_url: values.url_name,
      support_email: values.email_input,
      industry: values.category_input,
      entity_type: values.entity_type_input,
    };
    localStorage.setItem('store_details', JSON.stringify(postAPI));
  };

  return (
    <div className='buisness-SignupForm1-Container'>
      <div className='buisness-SignupForm1-Container__content'>
        <h4 className='buisness-SignupForm1-Container__content__title'>
          Let's set up your <br></br> account.
        </h4>
        <p className='buisness-SignupForm1-Container__content__subtitle'>
          We need information about your store and buisness for this step.
        </p>
      </div>
      <div className='buisness-SignupForm1-Container__form_container'>
        <Formik
          enableReinitialize={true}
          initialValues={bussinessInfo}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleSubmit,
            handleChange,
            setFieldValue,
            dirty,
            isValid,
            handleBlur,
            values,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className='buisness-signup1-field'>
                  <label
                    className='buisness-signup1-field__label'
                    htmlFor='store_input_buisnessSignup1'
                  >
                    Store Name
                  </label>
                  <div className='buisness-signup1-field__container-input-box'>
                    <InputField
                      value={values.store_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.store_name && touched.store_name
                          ? 'buisness-signup1-field__container-input-box__error'
                          : 'buisness-signup1-field__container-input-box__input-box'
                      }
                      id='store_input_buisnessSignup1'
                      name='store_name'
                      placeholder='Demo Shop'
                    ></InputField>
                    {errors.store_name && touched.store_name ? (
                      <div className='buisness-signup1-field__error-color'>
                        {errors.store_name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='buisness-signup1-field'>
                  <label
                    className='buisness-signup1-field__label'
                    htmlFor='url_input_buisnessSignup1'
                  >
                    Store URL
                  </label>
                  <div className='buisness-signup1-field__container-input-box'>
                    <InputField
                      value={values.url_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.url_name && touched.url_name
                          ? 'buisness-signup1-field__container-input-box__error'
                          : 'buisness-signup1-field__container-input-box__input-box'
                      }
                      id='url_input_buisnessSignup1'
                      name='url_name'
                      placeholder='demoshop.com'
                    ></InputField>
                    {errors.url_name && touched.url_name ? (
                      <div className='buisness-signup1-field__error-color'>
                        {errors.url_name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='buisness-signup1-field'>
                  <label
                    className='buisness-signup1-field__label'
                    htmlFor='email_input_buisnessSignup1'
                  >
                    Support email
                  </label>
                  <div className='buisness-signup1-field__container-input-box'>
                    <InputField
                      value={values.email_input}
                      onChange={handleChange}
                      className={
                        errors.email_input && touched.email_input
                          ? 'buisness-signup1-field__container-input-box__error'
                          : 'buisness-signup1-field__container-input-box__input-box'
                      }
                      id='email_input'
                      name='email_input'
                      placeholder='support@demoshop.com'
                      onBlur={handleBlur}
                    ></InputField>
                    {errors.email_input && touched.email_input ? (
                      <div className='buisness-signup1-field__error-color'>
                        {errors.email_input}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className='buisness-signup1-field'>
                  <label
                    className='buisness-signup1-field__label'
                    htmlFor='category_input_buisnessSignup1 '
                  >
                    <p>Industry</p>
                  </label>
                  <div className='buisness-signup1-field__container-input-box'>
                    <CategorySelect
                      selected={values.category_input}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.category_input && touched.category_input
                          ? 'buisness-signup1-field__container-input-box__error'
                          : 'buisness-signup1-field__container-input-box__input-box'
                      }
                      id='email_input_buisnessSignup1'
                      name='category_input'
                    ></CategorySelect>
                    {errors.category_input && touched.category_input ? (
                      <div className='buisness-signup1-field__error-color'>
                        {errors.category_input}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className='buisness-signup1-field'>
                  <label
                    className='buisness-signup1-field__label'
                    htmlFor='entity_input_buisnessSignup1'
                  >
                    Entity type
                  </label>
                  <div className='buisness-signup1-field__container-input-box'>
                    <EntityType
                      selected={values.entity_type_input}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.entity_type_input && touched.entity_type_input
                          ? 'buisness-signup1-field__container-input-box__error'
                          : 'buisness-signup1-field__container-input-box__input-box'
                      }
                      id='entity_input_buisnessSignup1'
                      name='entity_type_input'
                    ></EntityType>
                    {errors.entity_type_input && touched.entity_type_input ? (
                      <div className='buisness-signup1-field__error-color'>
                        {errors.entity_type_input}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className='container_for_firstpage_nextback'>
                  <button
                    className={
                      !(isValid)
                        ? 'container_for_firstpage_nextback__disabled_button'
                        : 'container_for_firstpage_nextback__submit_button'
                    }
                    type='submit'
                    disabled={!(isValid)}
                  >
                    Next
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BuisnessSignupform1;

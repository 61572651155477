import React from 'react';
import { Images } from '../../assets';
import { Link } from 'react-router-dom';

const EcommerceOptions = () => {
  const data = [
    {
      logo: Images.ShopifyLogo,
      name: 'Shopify',
      rightArrow: Images.Chevron_Right,
    },
    {
      logo: Images.salesforceLogo1,
      name: 'Salesforce Commerce Cloud',
      rightArrow: Images.Chevron_Right,
    },
    {
      logo: Images.AdobeCommerceLogo,
      name: 'Adobe Commerce (Magneto)',
      rightArrow: Images.Chevron_Right,
    },
    {
      logo: Images.customLogo,
      name: 'Custom',
      rightArrow: Images.Chevron_Right,
    },
    {
      logo: Images.logoforOther,
      name: 'Other',
      rightArrow: Images.Chevron_Right,
    },
  ];

  return (
    <div className='ecommerce_option_container'>
      {data.map((index, i) => {
        return (
          <div key={i} className='ecommerce_option_container__row'>
            <Link
              to='/home/selectEcommerce/bookDemo'
              className='ecommerce_option_container__link'
            >
              <span className='ecommerce_option_container__row__first_col'>
                <index.logo />
              </span>
            </Link>

            <Link
              to='/home/selectEcommerce/bookDemo'
              className='ecommerce_option_container__link'
            >
              <span className='ecommerce_option_container__row__second-col'>
                {index.name}
              </span>
            </Link>

            <Link
              to='/home/selectEcommerce/bookDemo'
              className='ecommerce_option_container__link'
            >
              <span className='ecommerce_option_container__row__third-col'>
                <index.rightArrow />
              </span>
            </Link>
          </div>
        );
      })}
      {/* </Link> */}
    </div>
  );
};

export default EcommerceOptions;

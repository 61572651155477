import * as React from 'react';
import Box from '@mui/material/Box';
import { Stepper, Step } from '@mui/material';
import NavigationBar from '../../components/navigation_bar/NavigationBar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import getStepContentform3 from '../../components/contentThirdBuisnessForm/stepContentThirdBuissness';
import getImageContentform3 from '../../components/contentThirdBuisnessForm/imageContentThirdBuissness';
import { useStyles } from './thirdform_styles';

const theme = createTheme({
  breakpoints: {
    values: {
      phone: 320,
      tablet: 768,
      'small-desktop': 1024,
      'mid-desktop': 1280,
      LGdesktop: 1920,
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
}));

const steps = ['Buisness', 'Bank Accounts'];

function BuisnessSignup3() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [counter, setCounter] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const className = classes.img_buisness_signup_page;

  const largeScreen = useMediaQuery(
    theme.breakpoints.between('phone', 'small-desktop')
  );

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    setActiveStep((steps) => steps + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCounter((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <NavigationBar></NavigationBar>
      <Grid
        direction={largeScreen ? 'column' : 'row'}
        container
        className={classes.buisnessSignup_grid_container}
      >
        <Item className={classes.buisnessSignup_item_container}>
          <Stepper
            className={classes.stepper_container}
            connector={<></>}
            activeStep={activeStep}
          >
            {steps.map((label, index) => (
              <Step
                className={
                  activeStep >= index
                    ? classes.steps_container_active
                    : classes.steps_container_disable
                }
                key={index}
                completed={completed[index]}
              >
                {label}
              </Step>
            ))}
          </Stepper>
          <div>
            <React.Fragment>
              {getStepContentform3(activeStep, handleNext, handleBack)}
            </React.Fragment>
          </div>
        </Item>
        <Item className={classes.buisnessSignup_item_container}>
          {getImageContentform3(activeStep, className)}
        </Item>
      </Grid>
    </Box>
  );
}

export default BuisnessSignup3;

import React from 'react';
import AddPersonForm from '../CompanyOwn/AddPersonForm';

const BuisnessSignupform1_4 = ({ handleNext, handleBack }) => {
  return (
    <div className='buisness-SignupForm2_2-Container'>
      <div className='buisness-SignupForm2_2-Container__content'>
        <h4 className='buisness-SignupForm2_2-Container__content__title'>
          Who is your company <br></br> CEO?
        </h4>
        <p className='buisness-SignupForm2_2-Container__content__subtitle'>
          We are legally required by AML laws to collect this information.
        </p>
      </div>
      <div>
        <AddPersonForm
          origin="company-ceo"
          handleNext={handleNext}
          handleBack={handleBack}
          addPerson={false}
        />
      </div>
    </div>
  );
};

export default BuisnessSignupform1_4;

import React from 'react';
import SelectInputBox from '../select_input/SelectInput';

const AnnualTxnVolume = ({ className, id, name, onChange }) => {
  const data = [
    {
      name: 'Below  £200,000',
      value: 'Below  £200,000',
    },
    {
      name: 'Between £200,000 and £2,000,000',
      value: 'Between £200,000 and £2,000,000',
    },
    {
      name: 'Between £200,0000 and £10,000,000',
      value: 'Between £200,0000 and £10,000,000',
    },
    {
      name: 'Over £10,000,000',
      value: 'Over £10,000,000',
    },
  ];

  return (
    <SelectInputBox
      onChange={onChange}
      data={data}
      className={className}
      id={id}
      name={name}
      title={'name'}
      value={'value'}
    ></SelectInputBox>
  );
};

export default AnnualTxnVolume;

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, FieldArray, getIn } from 'formik';
import SelectCountry from '../SelectCountry/SelectCountry';
import './CompanyOwn.scss';
import { Images } from '../../assets';
import { parse, isDate } from 'date-fns';
import { Row, Col, Container } from 'react-bootstrap';

const min = '01/01/1800';
const max = '31/12/2018';

const CompanysOwn = ({ handleNext, handleBack, addPerson }) => {
    const initialValues={
        person: [
          {
            partner_name: '',
            partner_lastName: '',
            birth_date: '',
            country_input: 'United Kingdom',
            city_name: '',
            address_name_line1: '',
            address_name_line2: '',
            postal_code: '',
          },
        ],
      }
    const [ownerInfo, setOwnerInfo] = useState(initialValues);
      
  const uk_postcode_regex =
    /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/;

  // const nameReg = /^[a-zA-Z]*$/;

  const validationSchema = Yup.object().shape({
    person: Yup.array().of(
      Yup.object().shape({
        partner_name: Yup.string()
          .required('First name cannot be blank'),
        partner_lastName: Yup.string()
          .required('Last name cannot be blank'),
        birth_date: Yup.date()
          .transform((value, originalValue) =>
            parse(originalValue, 'dd/MM/yyyy', new Date())
          )
          .typeError('Please enter a valid date of birth')
          .min(min, `startDate should be equal or greater than ${min}`)
          .max(max, `end date should not be greater than this date ${max}`)
          .required('DOB cannot be blank'),
        city_name: Yup.string().required('City name cannot be blank'),
        address_name_line1: Yup.string().required('Address cannot be blank'),
        postal_code: Yup.string()
          .matches(uk_postcode_regex, 'Please enter a valid post code')
          .required('Postal code cannot be blank'),
      })
    ),
  });
  
  const getStoredDetail = ()=>{
    localStorage.getItem('key_persons');
    let detail = localStorage.getItem('key_persons');
    if (detail != undefined && detail != null) {
      detail = JSON.parse(detail);
      let persons = [];
      detail.forEach(element => {
        if(!element.is_owner){
            return
        }
        persons.push({
            partner_name: element.first_name,
            partner_lastName: element.last_name,
            birth_date: element.date_of_birth,
            country_input: 'United Kingdom',
            city_name: element.address.city,
            address_name_line1: element.address.address_1 ?? '',
            address_name_line2: element.address.address_2 ?? '',
            postal_code: element.address.postcode,
            is_owner:element.is_owner
        })
        
      });
      setOwnerInfo({person:persons})    
    }
  }

  useEffect(() => {
    getStoredDetail();
  }, []);

  const onSubmit = (values) => {
    handleNext();
    const body = values.person.map((val) => {
      const postAPI = {
        first_name: val?.partner_name,
        last_name: val?.partner_lastName,
        date_of_birth: val?.birth_date,
        address: {
          city: val?.city_name,
          postcode: val?.postal_code,
          address_1: val?.address_name_line1,
          address_2: val?.address_name_line2,
          country: 'United Kingdom',
        },
        is_owner:true
      };
      return postAPI;
    });
    localStorage.setItem('key_persons', JSON.stringify(body));
  };

  const handleDateOfBirth = (e, callback) => {
    const { name, value } = e.target;
    if (value.length <= 10) {
      let dummy = value
        .replace(/^(\d\d)(\d)$/g, '$1/$2')
        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
        .replace(/[^\d\/]/g, '');
      callback(dummy);
    }
  };

  return (
    <div className='form_container_company_own'>
      <Formik
        enableReinitialize={true}
        initialValues={ownerInfo}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          dirty,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray
              name='person'
              render={({ push, remove }) => (
                <div>
                  {values.person &&
                    values.person.length > 0 &&
                    values.person.length < 5 &&
                    values.person.map((person, index) => (
                      <div key={index}>
                        {index === 0 ? null : (
                          <h1 className='form_addPerson__heading'>
                            Person #{index + 1}
                            <img
                              className='form_addPerson__heading__image'
                              src={Images.Closecross}
                              onClick={() => remove(index)}
                            ></img>
                          </h1>
                        )}
                        {index === 0 ? null : (
                          <hr className='form_addPerson__hr' />
                        )}
                        <div className='buisness-signup-form3_2'>
                          <label
                            className='buisness-signup-form3_2__label'
                            htmlFor={`person.${index}.partner_name`}
                          >
                            Full name
                          </label>
                          <div className='buisness-signup-form3_2__container-input-box'>
                            <Container className='buisness-signup-form3_2__container-input-box__container'>
                              <Row className='buisness-signup-form3_2__container-input-box__container__row'>
                                <Col className='buisness-signup-form3_2__container-input-box__container__row__col1'>
                                  {' '}
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder='First name'
                                    className={
                                      errors?.person?.[index]?.partner_name &&
                                      touched?.person?.[index]?.partner_name
                                        ? 'buisness-signup-form3_2__container-input-box__error_fullname'
                                        : 'buisness-signup-form3_2__container-input-box__fullname_input'
                                    }
                                    value={values.person[index].partner_name}
                                    id={`person.${index}.partner_name`}
                                    name={`person.${index}.partner_name`}
                                  ></input>
                                  {errors?.person?.[index]?.partner_name &&
                                  touched?.person?.[index]?.partner_lastName ? (
                                    <div className='buisness-signup-form3_2__error-color'>
                                      {errors?.person?.[index]?.partner_name}
                                    </div>
                                  ) : null}
                                </Col>
                                <Col className='buisness-signup-form3_2__container-input-box__container__row__col2'>
                                  {' '}
                                  <input
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder='Last name'
                                    className={
                                      errors?.person?.[index]
                                        ?.partner_lastName &&
                                      touched?.person?.[index]?.partner_lastName
                                        ? 'buisness-signup-form3_2__container-input-box__error_lastname'
                                        : 'buisness-signup-form3_2__container-input-box__fullname_input_lastname'
                                    }
                                    value={values.person[index].partner_lastName}
                                    id='partnerShip_input_buisnessSignup1'
                                    name={`person.${index}.partner_lastName`}
                                  ></input>
                                  {errors?.person?.[index]?.partner_lastName &&
                                  touched?.person?.[index]?.partner_lastName ? (
                                    <div className='buisness-signup-form3_2__error-color'>
                                      {
                                        errors?.person?.[index]
                                          ?.partner_lastName
                                      }
                                    </div>
                                  ) : null}
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>
                        <div className='buisness-signup-form3_2'>
                          <label
                            className='buisness-signup-form3_2__label'
                            htmlFor='Register_input_buisnessSignup1'
                          >
                            Date of Birth
                          </label>
                          <div className='buisness-signup-form3_2__container-input-box'>
                            <input
                              onChange={(e) => {
                                handleDateOfBirth(e, (value) => {
                                  setFieldValue(
                                    `person.${index}.birth_date`,
                                    value
                                  );
                                });
                              }}
                              value={values.person[index].birth_date}
                              onBlur={handleBlur}
                              type='text'
                              // maxLength={10}
                              className={
                                errors?.person?.[index]?.birth_date &&
                                touched?.person?.[index]?.birth_date
                                  ? 'buisness-signup-form3_2__container-input-box__error'
                                  : 'buisness-signup-form3_2__container-input-box__input-box'
                              }
                              placeholder=' /  / '
                              id='Register_input_buisnessSignup1'
                              name={`person.${index}.birth_date`}
                            ></input>
                            {errors?.person?.[index]?.birth_date &&
                            touched?.person?.[index]?.birth_date ? (
                              <div className='buisness-signup-form3_2__error-color'>
                                {errors?.person?.[index]?.birth_date}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className='buisness-signup-form2_address'>
                          <label
                            className='buisness-signup-form2_address__label'
                            htmlFor='url_input_buisnessSignup1'
                          >
                            Registered Address
                          </label>
                          <div className='buisness-signup-form2_address__container-input-box'>
                            <SelectCountry
                              value='United Kingdom'
                              onChange={handleChange}
                              className='buisness-signup-form2_address__container-input-box__postal_container__input-box2'
                              id='country_input_firstpage_flags'
                              name='country_input'
                            ></SelectCountry>
                          </div>
                        </div>

                        <div className='buisness-signup-form2_address'>
                          <label
                            className='buisness-signup-form2_address__label'
                            htmlFor='url_input_buisnessSignup1'
                          ></label>
                          <div className='buisness-signup-form2_address__container-input-box'>
                            <input
                              className={
                                errors?.person?.[index]?.address_name_line1 &&
                                touched?.person?.[index]?.address_name_line1
                                  ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                                  : 'buisness-signup-form2_address__container-input-box__postal_container'
                              }
                              type='text'
                              placeholder='Address Line 1'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.person[index].address_name_line1}
                              name={`person.${index}.address_name_line1`}
                            ></input>
                            {errors?.person?.[index]?.address_name_line1 &&
                            touched?.person?.[index]?.address_name_line1 ? (
                              <div className='buisness-signup-form2_address__error-color'>
                                {errors?.person?.[index]?.address_name_line1}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className='buisness-signup-form2_address'>
                          <label
                            className='buisness-signup-form2_address__label'
                            htmlFor='url_input_buisnessSignup1'
                          ></label>
                          <div className='buisness-signup-form2_address__container-input-box'>
                            <input
                              className={
                                errors?.person?.[index]?.address_name_line2 &&
                                touched?.person?.[index]?.address_name_line2
                                  ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                                  : 'buisness-signup-form2_address__container-input-box__postal_container'
                              }
                              type='text'
                              placeholder='Address Line 2'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.person[index].address_name_line2}
                              name={`person.${index}.address_name_line2`}
                            ></input>
                            {errors?.person?.[index]?.address_name_line2 &&
                            touched?.person?.[index]?.address_name_line2 ? (
                              <div className='buisness-signup-form2_address__error-color'>
                                {errors?.person?.[index]?.address_name_line2}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className='buisness-signup-form2_address'>
                          <label
                            className='buisness-signup-form2_address__label'
                            htmlFor='url_input_buisnessSignup1'
                          ></label>
                          <div className='buisness-signup-form2_address__container-input-box'>
                            <input
                              className={
                                errors?.person?.[index]?.postal_code &&
                                touched?.person?.[index]?.postal_code
                                  ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                                  : 'buisness-signup-form2_address__container-input-box__postal_container'
                              }
                              type='text'
                              placeholder='Post Code'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.person[index].postal_code}
                              name={`person.${index}.postal_code`}
                            ></input>
                            {errors?.person?.[index]?.postal_code &&
                            touched?.person?.[index]?.postal_code ? (
                              <div className='buisness-signup-form2_address__error-color'>
                                {errors?.person?.[index]?.postal_code}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className='buisness-signup-form2_address'>
                          <label
                            className='buisness-signup-form2_address__label'
                            htmlFor='url_input_buisnessSignup1'
                          ></label>
                          <div className='buisness-signup-form2_address__container-input-box'>
                            <input
                              className={
                                errors?.person?.[index]?.city_name &&
                                touched?.person?.[index]?.city_name
                                  ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                                  : 'buisness-signup-form2_address__container-input-box__postal_container'
                              }
                              type='text'
                              placeholder='City'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name={`person.${index}.city_name`}
                              value={values.person[index].city_name}
                            ></input>
                            {errors?.person?.[index]?.city_name &&
                            touched?.person?.[index]?.city_name ? (
                              <div className='buisness-signup-form2_address__error-color'>
                                {errors?.person?.[index]?.city_name}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {index === values.person.length - 1 &&
                          values.person.length < 4 && (
                            <button
                              className='add_person_button_radio_button'
                              onClick={() =>
                                push({
                                  partner_name: '',
                                  birth_date: '',
                                  country_input: 'United Kingdom',
                                  city_name: '',
                                  address_name: '',
                                  postal_code: '',
                                })
                              }
                            >
                              + Add Person
                            </button>
                          )}
                      </div>
                    ))}
                </div>
              )}
            ></FieldArray>

            <div className='container_for_nextback'>
              <button
                className='container_for_nextback__back_button'
                onClick={handleBack}
              >
                Back
              </button>
              <button
                className={
                  !(isValid)
                    ? 'container_for_nextback__disabled_button'
                    : 'container_for_nextback__submit_button'
                }
                type='submit'
                disabled={!(isValid)}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanysOwn;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  switchToProd: null,
  switchToTestMode: null,
  accountStatus: '',
  testMode: false,
  defaultTestMode: false,
};

const testmodeSlice = createSlice({
  name: 'testmode',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setTestMode: (state, action) => {
      return {
        ...state,
        testMode: action.payload,
      };
    },
    clearMerchantDetails: (state, action) => {
      return initialState;
    },
    saveMerchantDetails: (state, action) => {
      switch (action.payload) {
        case 'INREVIEW':
          return {
            ...state,
            switchToProd: false,
            switchToTestMode: true,
            accountStatus: 'INREVIEW',
            testMode: true,
            // defaultTestMode: true,
          };
        case 'DEACTIVATED':
          return {
            ...state,
            switchToProd: true,
            switchToTestMode: true,
            accountStatus: 'DEACTIVATED',
          };
        case 'ON_HOLD':
          return {
            ...state,
            switchToProd: true,
            switchToTestMode: true,
            accountStatus: 'ON_HOLD',
          };
        case 'LIVE':
          return {
            ...state,
            switchToProd: true,
            switchToTestMode: true,
            accountStatus: 'LIVE',
          };
      }
    },
  },
});

export const {
  setLoading,
  clearMerchantDetails,
  saveMerchantDetails,
  setTestMode,
} = testmodeSlice.actions;
export default testmodeSlice.reducer;

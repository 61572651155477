import React, { useState, useEffect } from 'react';
import CompanysOwn from '../CompanyOwn/CompanysOwn';

const StakeholderRadio = ({ handleNext, handleBack }) => {
  const [checked, setChecked] = React.useState('no_radio');

  const checkBoxChange = (e)=>{
    setChecked(e.target.value)
    localStorage.setItem('stakeholder',e.target.value)
    if(e.target.value == 'no_radio'){
        localStorage.removeItem('key_persons');
    }
  }
  
  const getStakeholder = ()=>{
    localStorage.getItem('stakeholder');
    let detail = localStorage.getItem('stakeholder');
    if (detail != undefined && detail != null) {
        setChecked(detail);
    }
  }

  useEffect(() => {
    getStakeholder();
  }, []);
  
  return (
    <div className='stakeholderradio-SignupForm1-Container'>
      <div className='stakeholderradio-SignupForm1-Container__content'>
        <h4 className='stakeholderradio-SignupForm1-Container__content__title'>
          Are there any people who <br /> own more than 25% of
          <br /> the company?
        </h4>
        <p className='stakeholderradio-SignupForm1-Container__content__subtitle'>
          Please provide information of the people that own more than 25% of{' '}
          <br /> shares or voting rights of the company or excercise similar
          control.
        </p>
      </div>
      <div className='stakeholderradio-SignupForm1-Container__radio_container'>
        <input
          className='stakeholderradio-SignupForm1-Container__radio_container__input'
          type='radio'
          id='no_radio_label'
          name='yesno_radio'
          value='no_radio'
          onChange={(e) => checkBoxChange(e)}
          checked={checked === 'no_radio'}
        ></input>
        <label
          className='stakeholderradio-SignupForm1-Container__radio_container__label'
          htmlFor='no_radio_label'
        >
          No
        </label>
        <input
          className='stakeholderradio-SignupForm1-Container__radio_container__input'
          checked={checked === 'yes_radio'}
          id='yes_radio_label'
          onChange={(e) => checkBoxChange(e)}
          type='radio'
          value='yes_radio'
          name='yesno_radio'
        ></input>
        <label
          className='stakeholderradio-SignupForm1-Container__radio_container__label'
          htmlFor='yes_radio_label'
        >
          Yes
        </label>
      </div>
      {checked === 'no_radio' ? (
        <div className='container_for_nextback'>
          <button
            className='container_for_nextback__submit_button'
            type='submit'
            onClick={handleNext}
          >
            Next
          </button>
          <button
            className='container_for_nextback__back_button'
            onClick={handleBack}
          >
            Back
          </button>
          
        </div>
      ) : (
        <div className='stakeholder_form'>
          <CompanysOwn
            handleNext={handleNext}
            handleBack={handleBack}
            addPerson
          />
        </div>
      )}
    </div>
  );
};

export default StakeholderRadio;

import React from 'react';
import NavigationBar from '../../components/navigation_bar/NavigationBar';
import './MerchantLogin.scss';
import MerchantLoginPage from '../../components/MerchantLoginPage/MerchantLoginPage';

const MerchantLogin = () => {
  return (
    <div>
      <NavigationBar />
      <div className='merchantLogin'>
        <div className='merchantLogin_access_form'>Log In </div>
        <div className='setup_access1'>
          <MerchantLoginPage />
        </div>
      </div>
    </div>
  );
};

export default MerchantLogin;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  merchant_id: '',
  first_name: '',
  last_name: '',
  shop_name: '',
  merchant_user_id: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveAuth: (state, action) => {
      return {
        ...state,
        merchant_id: action.payload.user.merchant.merchant_id,
        first_name: action.payload.user.first_name,
        last_name: action.payload.user.last_name,
        shop_name: action.payload.user.merchant.name,
        merchant_user_id: action.payload.user.merchant_user_id,
      };
    },
    clearAuth: (state, action) => {
      return initialState;
    },
  },
});

export const { saveAuth, clearAuth } = authSlice.actions;
export default authSlice.reducer;

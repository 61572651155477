import { combineReducers } from 'redux';
import appSlice from './appSlice';
import authSlice from './authSlice';
import testmodeSlice from './testmodeSlice';
import merchantSlice from './merchantSlice';

export const RootReducers = combineReducers({
  app: appSlice,
  auth: authSlice,
  testmode: testmodeSlice,
  merchant: merchantSlice,
});

export const ACCESS_TOKEN = 'token';
export const MERCHANT_ID = 'merchant_id';
export const MERCHANT_USER_ID = 'merchant_user_id';
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const SHOP_NAME = 'shop_name';
export const TEST_MODE = 'test_mode';
export const TEST__DEFAULT_MODE = 'test_default_mode';
export const BRAND_NAME = 'shop_name';
export const MERCHANT_APIKEY = 'merchant_id_api_key';
export const MERCHANT_DETAILS = 'merchant_details';

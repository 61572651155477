import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputField from '../input_field/Input';
import { useNavigate } from 'react-router-dom';

const BuisnessSignupform2_22 = ({ handleNext }) => {
  const navigate = useNavigate();
  const initialValues = {
    company_legal_name: '',
    vat_number: '',
    country_input: 'United Kingdom',
    city_name: '',
    address_name_line1: '',
    address_name_line2: '',
    postal_code: '',
  };
  const [companyInfo, setCompanyInfo] = useState(initialValues);

  const uk_postcode_regex =
    /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/;

  const getStoredDetail = async () => {
    let detail = localStorage.getItem('company_info');
    if (detail != undefined && detail != null) {
      detail = JSON.parse(detail);
      setCompanyInfo({
        company_legal_name: detail.partnership_name,
        vat_number: detail.vat_number,
        city_name: detail.address.city,
        country_input: 'United Kingdom',
        address_name_line1: detail.address.address_1,
        address_name_line2: detail.address.address_2,
        postal_code: detail.address.postcode,
      });
      console.log('detail',detail);
    }
  };

  useEffect(() => {
    getStoredDetail();
  }, []);

  const handleBack = () => {
    navigate('/onBoarding');
  };

  const validationSchema = Yup.object().shape({
    company_legal_name: Yup.string().required(''),
    city_name: Yup.string().required(''),
    // address_name_line1: Yup.string().required(''),
    postal_code: Yup.string()
      .matches(uk_postcode_regex, 'Please enter a valid post code')
      .required(''),
  });

  const onSubmit = (values) => {
    handleNext();
    const postAPI = {
      partnership_name: values.company_legal_name,
      vat_number: values.vat_number,
      address: {
        city: values.city_name,
        postcode: values.postal_code,
        address_1: values.address_name_line1,
        address_2: values.address_name_line2,
        country: values.country_input,
      },
    };
    localStorage.setItem('company_info', JSON.stringify(postAPI));
  };

  return (
    <div className='buisness-SignupForm1_2-Container'>
      <div className='buisness-SignupForm1_2-Container__content'>
        <h4 className='buisness-SignupForm1_2-Container__content__title'>
          Your company <br></br> information
        </h4>
        <p className='buisness-SignupForm1_2-Container__content__subtitle'>
          About your registered company
        </p>
      </div>
      <div className='buisness-SignupForm1_2-Container__form_container'>
        <Formik
          validationSchema={validationSchema}
          enableReinitialize={true}
          initialValues={companyInfo}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            dirty,
            isValid,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div className='buisness-signup-form2'>
                  <label
                    className='buisness-signup-form2__label'
                    htmlFor='store_input_buisnessSignup1'
                  >
                    Partnership name
                  </label>
                  <div className='buisness-signup-form2__container-input-box'>
                    <InputField
                      value={values.company_legal_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='Demo Shop'
                      className={
                        errors.company_legal_name && touched.company_legal_name
                          ? 'buisness-signup-form2__container-input-box__error'
                          : 'buisness-signup-form2__container-input-box__input-box'
                      }
                      id='store_input_buisnessSignup1'
                      name='company_legal_name'
                    ></InputField>
                    {errors.company_legal_name && touched.company_legal_name ? (
                      <div className='buisness-signup-form2__error-color'>
                        {errors.company_legal_name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='buisness-signup-form2'>
                  <label
                    className='buisness-signup-form2__label'
                    htmlFor='vat_number'
                    style={{ paddingTop: '20px' }}
                  >
                    VAT Number{' '}
                    <p className='optional_style_form1_2'>(Optional)</p>
                  </label>
                  <div className='buisness-signup-form2__container-input-box'>
                    <InputField
                      value={values.vat_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder='GB123456789'
                      className={
                        errors.vat_number && touched.vat_number
                          ? 'buisness-signup-form2__container-input-box__error'
                          : 'buisness-signup-form2__container-input-box__input-box'
                      }
                      id='vat_number'
                      name='vat_number'
                    ></InputField>
                    {errors.vat_number && touched.vat_number ? (
                      <div className='input_field_error_color'>
                        {errors.vat_number}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='buisness-signup-form2_address'>
                  <label
                    className='buisness-signup-form2_address__label'
                    htmlFor='url_input_buisnessSignup1'
                  >
                    Registered Address
                  </label>
                  <div className='buisness-signup-form2_address__container-input-box'>
                    <input
                      className='buisness-signup-form2_address__container-input-box__postal_container__disabled'
                      type='text'
                      value={values.country_input}
                      placeholder='United Kingdom'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='contry'
                      disabled
                    ></input>
                  </div>
                </div>
                <div className='buisness-signup-form2_address'>
                  <label
                    className='buisness-signup-form2_address__label'
                    htmlFor='url_input_buisnessSignup1'
                  ></label>
                  <div className='buisness-signup-form2_address__container-input-box'>
                    <input
                      className={
                        errors.address_name_line1 && touched.address_name_line1
                          ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                          : 'buisness-signup-form2_address__container-input-box__postal_container'
                      }
                      type='text'
                      value={values.address_name_line1}
                      placeholder='Address Line 1'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id='address_name_line1'
                      name='address_name_line1'
                    ></input>
                    {errors.address_name_line1 && touched.address_name_line1 ? (
                      <div className='buisness-signup-form2_address__error-color'>
                        {errors.address_name_line1}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='buisness-signup-form2_address'>
                  <label
                    className='buisness-signup-form2_address__label'
                    htmlFor='url_input_buisnessSignup1'
                  ></label>
                  <div className='buisness-signup-form2_address__container-input-box'>
                    <input
                      className={
                        errors.address_name_line2 && touched.address_name_line2
                          ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                          : 'buisness-signup-form2_address__container-input-box__postal_container'
                      }
                      value={values.address_name_line2}
                      type='text'
                      placeholder='Address Line 2'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='address_name_line2'
                      id='address_name_line2'
                    ></input>
                    {errors.address_name_line2 && touched.address_name_line2 ? (
                      <div className='buisness-signup-form2_address__error-color'>
                        {errors.address_name_line2}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className='buisness-signup-form2_address'>
                  <label
                    className='buisness-signup-form2_address__label'
                    htmlFor='url_input_buisnessSignup1'
                  ></label>
                  <div className='buisness-signup-form2_address__container-input-box'>
                    <input
                      className={
                        errors.postal_code && touched.postal_code
                          ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                          : 'buisness-signup-form2_address__container-input-box__postal_container'
                      }
                      value={values.postal_code}
                      type='text'
                      placeholder='Post Code'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='postal_code'
                      id='postal_code'
                    ></input>
                    {errors.postal_code && touched.postal_code ? (
                      <div className='buisness-signup-form2_address__error-color'>
                        {errors.postal_code}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className='buisness-signup-form2_address'>
                  <label
                    className='buisness-signup-form2_address__label'
                    htmlFor='url_input_buisnessSignup1'
                  ></label>
                  <div className='buisness-signup-form2_address__container-input-box'>
                    <input
                      className={
                        errors.city_name && touched.city_name
                          ? 'buisness-signup-form2_address__container-input-box__postal_container_error'
                          : 'buisness-signup-form2_address__container-input-box__postal_container'
                      }
                      type='text'
                      value={values.city_name}
                      placeholder='City'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name='city_name'
                      id='city_name'
                    ></input>
                    {errors.city_name && touched.city_name ? (
                      <div className='buisness-signup-form2_address__error-color'>
                        {errors.city_name}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className='container_for_nextback'>
                  <button
                    className='container_for_nextback__back_button'
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button
                    className={
                      !(isValid)
                        ? 'container_for_nextback__disabled_button'
                        : 'container_for_nextback__submit_button'
                    }
                    type='submit'
                    disabled={!(isValid)}
                  >
                    Next
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BuisnessSignupform2_22;
